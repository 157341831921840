import * as z from "zod";

export const firestoreDocCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
  emoji: z.string(),
  created_timestamp_secs: z.number(),
  order_position: z.number(),
});

export type FirestoreDocCategory = z.infer<typeof firestoreDocCategorySchema>;

import { useContext } from "react";
import { BudgetsContext } from "../firebaseio/BudgetsContext";
import styles from "./BudgetScreen.module.scss";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { FirestoreDocCategory } from "breadcommon";
import { BudgetLineItem } from "breadcommon/src/BreadBudget";
import { DollarAmount } from "../common/DollarAmount";
import {
  firestoreAddEmptyBudgetLineItem,
  firestoreDeleteBudget,
  firestoreRemoveBudgetLineItem,
  firestoreUpdateBudgetLineItem,
} from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { HorizontalSpacer, VerticalSpacer } from "../utils/Utils";
import { Icon } from "../Icon";

export function BudgetEditor(props: { budgetId: string }): JSX.Element {
  const user = useContext(UserContext);
  const budgets = useContext(BudgetsContext);
  const categories = useContext(CategoriesContext);
  const budget = budgets.get(props.budgetId);

  async function deleteCurrentBudget() {
    await firestoreDeleteBudget(user.uid, props.budgetId);
  }

  if (budget === undefined) {
    return <div>ERROR: No budget with ID "{props.budgetId}" was found.</div>;
  }

  // make sure budget has all and only existing categories
  budget.line_items.forEach(async (_, category_id) => {
    if (!categories.has(category_id)) {
      await firestoreRemoveBudgetLineItem(user.uid, budget.id, category_id);
    }
  });
  categories.forEach(async (_, category_id) => {
    if (!budget.line_items.has(category_id)) {
      await firestoreAddEmptyBudgetLineItem(user.uid, budget.id, category_id);
    }
  });

  function line(
    c: FirestoreDocCategory,
    line_item: BudgetLineItem
  ): JSX.Element {
    return (
      <div key={c.id} className={styles.lineItem}>
        <div className={styles.categoryName}>{c.emoji + "   " + c.name}</div>
        <HorizontalSpacer width={20}></HorizontalSpacer>
        <input
          key={props.budgetId} // reset the default value when the budget changes
          className={styles.formulaInput}
          type="text"
          placeholder="-12 * 30"
          defaultValue={line_item.formula}
          onChange={async (e) => {
            const newFormula = e.target.value;
            await firestoreUpdateBudgetLineItem(
              user.uid,
              props.budgetId,
              c.id,
              newFormula
            );
          }}
        />
        <HorizontalSpacer width={20}></HorizontalSpacer>
        <div>
          {!line_item.formula_is_valid ? (
            "Invalid Expression"
          ) : (
            <DollarAmount
              n={line_item.amount}
              includeDecimals={false}
              showPlus={true}
              showMinus={true}
              showDollarSign={true}
              monospaceFont={false}
              align="left"
            />
          )}
        </div>
      </div>
    );
  }

  const lines: JSX.Element[] = Array.from(categories.values())
    .sort((c1, c2) => c1.order_position - c2.order_position)
    .map((c) => line(c, budget.line_items.get(c.id)!));
  return (
    <div>
      <div className={styles.headerBar}>
        <div className={styles.leftHeader}>
          <div className={styles.budgetName}>{budget.name}</div>
          <HorizontalSpacer width={20}></HorizontalSpacer>
          {budget.beginDateTimestampSecs.format("MMM D, YYYY")} —{" "}
          {budget.endDateTimestampSecs.format("MMM D, YYYY")}
        </div>
        <Icon name={"delete"} size={20} onClick={deleteCurrentBudget}></Icon>
      </div>
      <VerticalSpacer height={25} />
      {lines}
    </div>
  );
}

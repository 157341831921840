import { BreadAccount, FirestoreDocUser } from "breadcommon";
import { createContext, useEffect, useState } from "react";
import { firestoreAccountsSubscribe } from "./firestoreIo";

export const AccountsContext = createContext<Map<string, BreadAccount>>(
  new Map()
);

export function AccountsContextProvider(props: {
  children: JSX.Element;
  user: FirestoreDocUser;
  markInitialLoadComplete: () => void;
}): JSX.Element {
  const [Accounts, setAccounts] = useState<Map<string, BreadAccount>>(
    new Map()
  );

  useEffect(() => {
    return firestoreAccountsSubscribe(
      props.user.uid,
      setAccounts,
      props.markInitialLoadComplete
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.uid]);

  return (
    <AccountsContext.Provider value={Accounts}>
      {props.children}
    </AccountsContext.Provider>
  );
}

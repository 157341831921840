import styles from "../settings_screen/accounts/AddAccountModal.module.scss";
import buttonStyles from "../common/Buttons.module.scss";
import { Icon } from "../Icon";
import { SetStateAction, useContext, useState } from "react";
import { lineWobble } from "ldrs";
import { DatePicker } from "@mui/x-date-pickers";
import { firestoreCreateNewEmptyBudget } from "../firebaseio/firestoreIo";
import dayjs, { Dayjs } from "dayjs";
import { UserContext } from "../firebaseio/UserContext";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { BasicModal } from "../common/BasicModal";

// https://uiball.com/ldrs/ are web components that must be registered before
// use
lineWobble.register();

export function CreateNewBudgetModal(props: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const user = useContext(UserContext);
  const categories = useContext(CategoriesContext);
  const defaultBegin = dayjs().startOf("year");
  const defaultEnd = dayjs().endOf("year");

  const [name, setName] = useState<string>("");
  const [beginDate, setBeginDate] = useState<Dayjs>(defaultBegin);
  const [endDate, setEndDate] = useState<Dayjs>(defaultEnd);

  async function saveAndClose() {
    await firestoreCreateNewEmptyBudget(
      user.uid,
      name,
      beginDate,
      endDate,
      Array.from(categories.keys())
    );
    props.setIsOpen(false);
  }

  let modalContent: JSX.Element = (
    <div>
      <div>
        <div>Budget Name</div>
        <input type="text" onChange={(e) => setName(e.target.value)} />
      </div>
      <div>
        <div>Begin Date</div>
        <DatePicker
          defaultValue={beginDate}
          onChange={(d) => {
            console.log(d);
            setBeginDate(d?.startOf("day") ?? defaultBegin);
          }}
          slotProps={{ textField: { size: "small" } }}
        />
      </div>
      <div>
        <div>End Date</div>
        <DatePicker
          defaultValue={endDate}
          onChange={(d) => setEndDate(d?.endOf("day") ?? defaultEnd)}
          slotProps={{ textField: { size: "small" } }}
        />
      </div>
      <div>
        <button
          className={buttonStyles.saveButton}
          disabled={name === ""}
          onClick={() => {
            saveAndClose();
          }}
        >
          Save
        </button>
      </div>
    </div>
  );

  return (
    <BasicModal isOpen={props.isOpen} height={400} width={500}>
      <div className={styles.contentContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.title}>Create New Budget</div>
          <Icon
            name="close"
            size={23}
            className={styles.closeButton}
            onClick={() => props.setIsOpen(false)}
          ></Icon>
        </div>
        {modalContent}
      </div>
    </BasicModal>
  );
}

import styles from "./AccountRow.module.scss";
import { HorizontalSpacer } from "../../utils/Utils";
import { Icon } from "../../Icon";
import { useContext, useState } from "react";
import { AccountSettingsModal } from "./AccountSettingsModal";
import { BreadAccount } from "breadcommon";
import { InstitutionsContext } from "../../firebaseio/InstitutionsContext";
import { useGetInstitutionFromAccount } from "../../utils/customHooks";
import { InstitutionLogo } from "../../common/InstitutionLogo";
import { DollarAmount } from "../../common/DollarAmount";

export function AccountRow(props: { account: BreadAccount }): JSX.Element {
  const [accountSettingsModalIsOpen, setAccountSettingsModalIsOpen] =
    useState<boolean>(false);
  const institutions = useContext(InstitutionsContext);
  const horizontalSepAmt = 50;

  let institution = useGetInstitutionFromAccount(props.account);
  if (
    props.account.institution_id !== null &&
    institutions.has(props.account.institution_id)
  ) {
    institution = institutions.get(props.account.institution_id);
  }

  return (
    <div className={styles.container}>
      <InstitutionLogo institution={institution} size={50}></InstitutionLogo>
      <HorizontalSpacer width={30}></HorizontalSpacer>
      <div className={styles.institutionName}>{institution?.name}</div>
      <HorizontalSpacer width={horizontalSepAmt}></HorizontalSpacer>
      <div className={styles.accountName}>{props.account.name}</div>
      <HorizontalSpacer width={horizontalSepAmt}></HorizontalSpacer>
      <div className={styles.accountMaskColumn}>
        {props.account.mask ? (
          <div className={styles.accountMask}>{"..." + props.account.mask}</div>
        ) : null}
      </div>
      <HorizontalSpacer width={horizontalSepAmt}></HorizontalSpacer>
      <div className={styles.accountType}>{props.account.type}</div>
      <HorizontalSpacer width={horizontalSepAmt}></HorizontalSpacer>
      <div className={styles.accountBalanceColumn}>
        <div className={styles.accountBalance}>
          <DollarAmount
            n={
              props.account.current_balance ? props.account.current_balance : 0
            }
            includeDecimals={false}
            showPlus={false}
            showMinus={true}
            showDollarSign={true}
            monospaceFont={false}
            align="right"
          />
        </div>
      </div>
      <HorizontalSpacer width={horizontalSepAmt}></HorizontalSpacer>
      <Icon
        name={"settings"}
        size={22}
        className={styles.settingsButton}
        onClick={() => {
          setAccountSettingsModalIsOpen(true);
        }}
      ></Icon>
      {accountSettingsModalIsOpen ? (
        <AccountSettingsModal
          isOpen={accountSettingsModalIsOpen}
          setIsOpen={setAccountSettingsModalIsOpen}
          initialAccount={props.account}
        ></AccountSettingsModal>
      ) : null}
    </div>
  );
}

import * as z from "zod";
import { ACCOUNT_STATUS_VALUES, AccountStatuses } from "./AccountStatuses";
import dayjs from "dayjs";

const systemDataAccountSchema = z.object({
  status: z.enum(ACCOUNT_STATUS_VALUES),
  plaid_item_id: z.string().nullable(),
  institution_id: z.string().nullable(),
});

// From https://plaid.com/docs/api/accounts/#accountsget
const plaidDataAccountSchema = z.object({
  account_id: z.string(),
  balances: z.object({
    available: z.number().nullable(),
    current: z.number().nullable(),
    limit: z.number().nullable(),
    iso_currency_code: z.string().nullable(),
    unofficial_currency_code: z.string().nullable(),
    last_updated_datetime: z.string().nullable().optional(),
  }),
  mask: z.string().nullable(),
  name: z.string(),
  official_name: z.string().nullable(),
  type: z.string(),
  subtype: z.string().nullable(),
  persistent_account_id: z.string().optional(),
});

const manualDataAccountSchema = z.object({
  name: z.string().nullable(),
  sync_start_timestamp_secs: z.number(), // unix timestamp (secs)
  order_position: z.number(),
});

export const firestoreDocAccountSchema = z.object({
  system_data: systemDataAccountSchema,
  plaid_data: plaidDataAccountSchema,
  manual_data: manualDataAccountSchema,
});

export type FirestoreDocAccount = z.infer<typeof firestoreDocAccountSchema>;

// IMPORTANT: The three definitions below must be kept in sync
// anytime this file is modified.

// IMPORTANT: Values cannot be removed without running a backfill job on the DB
// (because this will invalidate values in our type system that are already in
// use).

// You might be thinking:
// "this seems unnecessarily complicated... why didn't he use a typescript enum?
// Why is the same thing defined three times? What's going on here?"
//
// Well, this file should change very infrequently (if ever). And, with these
// three definitions this funnily-constructed enum becomes very easy to use
// and has very elegant typing across zod and typescript.

export const AccountStatuses: {
  NEW_SELECTED: "NEW_SELECTED";
  NEW_UNSELECTED: "NEW_UNSELECTED";
  ACTIVE: "ACTIVE";
  CLOSED: "CLOSED";
} = {
  NEW_SELECTED: "NEW_SELECTED",
  NEW_UNSELECTED: "NEW_UNSELECTED",
  ACTIVE: "ACTIVE",
  CLOSED: "CLOSED",
};

if (Object.keys(AccountStatuses).length === 0) {
  throw "AccountStatuses cannot be empty.";
}

// For use with typescript types.
// Must keep this up-to-date with the values defined in AccountStatuses above!
export type AccountStatuses = keyof typeof AccountStatuses;

// For use with enums in zod schemas (https://zod.dev/?id=zod-enums).
// Must keep this up-to-date with the values defined in AccountStatuses above!
export const ACCOUNT_STATUS_VALUES = [
  "NEW_SELECTED",
  "NEW_UNSELECTED",
  "ACTIVE",
  "CLOSED",
] as const;

import * as z from "zod";
import { MAIN_NAV_SCREEN_VALUES } from "./MainNavScreens";

export const firestoreDocPreferencesSchema = z.object({
  web: z.object({
    home_screen: z.enum(MAIN_NAV_SCREEN_VALUES)
  }),
});

/**
 * This type holds the page state necessary to remember where a user is
 * on the website, and navigate back there when they reopen the page.
 */
export type FirestoreDocPreferences = z.infer<typeof firestoreDocPreferencesSchema>;

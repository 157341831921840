import { useContext } from "react";
import { AuthContext } from "./firebaseio/AuthContext";

export function AuthLoadScreen(props: { children: JSX.Element }) {
  const auth = useContext(AuthContext);

  if (auth === undefined) {
    // We don't know if the user is already logged in or not yet, which is
    // important for deciding which screen to display first.
    return <div />;
  }

  return props.children;
}

import { LoadUser } from "./LoadUser";
import { useContext } from "react";
import { AuthContext } from "./firebaseio/AuthContext";
import { UrlContextProvider } from "./UrlContext";

export function LoggedIn(): JSX.Element {
  const auth = useContext(AuthContext);

  if (!auth) {
    return <div>Something has gone wrong. Please refresh the page.</div>;
  }

  return (
    <UrlContextProvider>
      <LoadUser user={auth}></LoadUser>
    </UrlContextProvider>
  );
}

import { useState } from "react";
import { CategorySettings } from "./CategorySettings";
import { ProfileSettings } from "./ProfileSettings";
import { RuleSettings } from "./RuleSettings";
import { AccountsSettings } from "./accounts/AccountsSettings";
import { ScreenWithLeftNav } from "../common/ScreenWithLeftNav";

type SettingsNavScreens = "Accounts" | "Categories" | "Rules" | "Profile";

export function SettingsScreen(props: {
  contentIsScrolled: boolean;
  handleScrollForRibbon: (event: Event, resetToNotScrolled?: boolean) => void;
  setNotScrolled: () => void;
}): JSX.Element {
  const [settingsNavScreen, setSettingsNavScreen] =
    useState<SettingsNavScreens>("Accounts");

  function getSettingsScreen(current: SettingsNavScreens): JSX.Element {
    switch (current) {
      case "Accounts":
        return <AccountsSettings></AccountsSettings>;
      case "Categories":
        return <CategorySettings></CategorySettings>;
      case "Rules":
        return <RuleSettings></RuleSettings>;
      case "Profile":
        return <ProfileSettings></ProfileSettings>;
    }
  }

  return (
    <ScreenWithLeftNav
      contentIsScrolled={props.contentIsScrolled}
      handleScrollForRibbon={props.handleScrollForRibbon}
      setNotScrolled={props.setNotScrolled}
      navOptions={[
        { name: "Accounts", iconName: "account_balance" },
        { name: "Categories", iconName: "category" },
        { name: "Rules", iconName: "gavel" },
        { name: "Profile", iconName: "person" },
      ]}
      selectedNavOptionName={settingsNavScreen}
      handleMenuItemClicked={setSettingsNavScreen}
    >
      {getSettingsScreen(settingsNavScreen)}
    </ScreenWithLeftNav>
  );
}

import transactionHeaderStyles from "./TransactionsHeader.module.scss";
import inlineTransactionStyles from "./InlineTransaction.module.scss";
import { HorizontalSpacer } from "../utils/Utils";
import { Icon } from "../Icon";
import { useContext } from "react";
import { SortColumns, SortOrders, TransactionsNavContext } from "../Scaffold";
import { ReviewedIcon } from "../common/ReviewedIcon";

export function TransactionsHeader(): JSX.Element {
  const [transactionsNav, setTransactionsNav] = useContext(
    TransactionsNavContext
  );

  let betweenFieldsSpacerWidth = 20;

  function rotateBooleanFilter(filterValue: boolean | null): boolean | null {
    if (filterValue === null) {
      return true;
    } else if (filterValue === true) {
      return false;
    } else { // if false
      return null;
    }
  }

  let sortIconName: "arrow_circle_down" | "arrow_circle_up" =
    "arrow_circle_down";
  if (transactionsNav.sortOrder === SortOrders.Ascending) {
    sortIconName = "arrow_circle_up";
  }

  function SwitchSortOrder() {
    setTransactionsNav((prevState) => ({
      ...prevState,
      sortOrder:
        prevState.sortOrder === SortOrders.Descending
          ? SortOrders.Ascending
          : SortOrders.Descending,
    }));
  }

  function setSortColumn(column: SortColumns) {
    setTransactionsNav((prevState) => ({
      ...prevState,
      sortColumn: column,
      sortOrder: SortOrders.Descending,
    }));
  }

  function getSortPlaceholderIcon(column: SortColumns) {
    return (
      <Icon
        name={"arrow_circle_down"}
        size={19}
        fill={false}
        className={transactionHeaderStyles.sortPlaceholderIcon}
        onClick={() => setSortColumn(column)}
      ></Icon>
    );
  }

  const sortIcon = (
    <Icon
      name={sortIconName}
      size={19}
      fill={true}
      className={transactionHeaderStyles.sortIcon}
      onClick={SwitchSortOrder}
    ></Icon>
  );

  const sortSpacerWidth = 15;

  return (
    <div className={transactionHeaderStyles.transactionHeaderContainer}>
      <div className={inlineTransactionStyles.dateColumn}>
        <div className={transactionHeaderStyles.transactionHeader}>
          <div className={transactionHeaderStyles.transactionHeaderName}>
            Date
          </div>
          <HorizontalSpacer width={sortSpacerWidth} />
          {transactionsNav.sortColumn === SortColumns.Date
            ? sortIcon
            : getSortPlaceholderIcon(SortColumns.Date)}
        </div>
      </div>
      <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
      <div className={inlineTransactionStyles.accountColumn}>
        <div className={transactionHeaderStyles.transactionHeader}>
          <div className={transactionHeaderStyles.transactionHeaderName}>
            Account
          </div>
          <HorizontalSpacer width={sortSpacerWidth} />
          {transactionsNav.sortColumn === SortColumns.Account
            ? sortIcon
            : getSortPlaceholderIcon(SortColumns.Account)}
        </div>
      </div>
      <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
      <div className={inlineTransactionStyles.merchantColumn}>
        <div className={transactionHeaderStyles.transactionHeader}>
          <div className={transactionHeaderStyles.transactionHeaderName}
            title="For some transactions, like credit card transactions, the origin is the Merchant. 
            For others, it is the type (and sometimes subtype) of the transaction, as categorized by your financial institution."
          >
            Origin
          </div>
          <HorizontalSpacer width={sortSpacerWidth} />
          {transactionsNav.sortColumn === SortColumns.Merchant
            ? sortIcon
            : getSortPlaceholderIcon(SortColumns.Merchant)}
        </div>
      </div>
      <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
      <div className={inlineTransactionStyles.descriptionColumn}>
        <div className={transactionHeaderStyles.transactionHeader}>
          <div className={transactionHeaderStyles.transactionHeaderName}>
            Description
          </div>
          <HorizontalSpacer width={sortSpacerWidth} />
          {transactionsNav.sortColumn === SortColumns.Description
            ? sortIcon
            : getSortPlaceholderIcon(SortColumns.Description)}
        </div>
      </div>
      <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
      <div className={inlineTransactionStyles.categoryColumn}>
        <div className={transactionHeaderStyles.transactionHeader}>
          <div className={transactionHeaderStyles.transactionHeaderName}>
            Category
          </div>
          <HorizontalSpacer width={sortSpacerWidth} />
          {transactionsNav.sortColumn === SortColumns.Category
            ? sortIcon
            : getSortPlaceholderIcon(SortColumns.Category)}
        </div>
      </div>
      <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
      <div className={inlineTransactionStyles.rulesColumn}>
        <div
          className={`${transactionHeaderStyles.transactionHeader} ${transactionHeaderStyles.iconHeader}`}
        >
          <div
            className={`${transactionHeaderStyles.transactionHeaderName} ${transactionHeaderStyles.iconHeaderName}`}
          >
            <Icon name={"gavel"} size={19} />
          </div>
        </div>
      </div>
      <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
      <div className={inlineTransactionStyles.reviewedColumn}>
        <div
          className={`${transactionHeaderStyles.transactionHeader} ${transactionHeaderStyles.iconHeader}`}
        >
          <div
            className={`${transactionHeaderStyles.transactionHeaderName} ${transactionHeaderStyles.iconHeaderName} ${transactionHeaderStyles.filterIcon}`}
          >
            <ReviewedIcon
              reviewed={transactionsNav.reviewedFilter}
              onClick={() => {
                setTransactionsNav((prevState) => ({
                  ...prevState,
                  reviewedFilter: rotateBooleanFilter(prevState.reviewedFilter)
                }));
              }}
              tooltip={`Showing ${transactionsNav.reviewedFilter === true ? "only Reviewed"
                : transactionsNav.reviewedFilter === false ? "only Unreviewed"
                  : "all"} transactions. Click to change.`}
            />
          </div>
        </div>
      </div>
      <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
      <div className={inlineTransactionStyles.amountColumn}>
        <div
          className={`${transactionHeaderStyles.transactionHeader} ${transactionHeaderStyles.alignRight}`}
        >
          {transactionsNav.sortColumn === SortColumns.Amount
            ? sortIcon
            : getSortPlaceholderIcon(SortColumns.Amount)}
          <HorizontalSpacer width={sortSpacerWidth} />
          <div className={transactionHeaderStyles.transactionHeaderName}>
            Amount
          </div>
        </div>
      </div>
    </div>
  );
}

import { InlineTransaction } from "./InlineTransaction";
import styles from "./TransactionsScreen.module.scss";
import { useContext } from "react";
import { TransactionsContext } from "../firebaseio/TransactionsContext";
import { AccountsContext } from "../firebaseio/AccountsContext";
import { SortColumns, SortOrders, TransactionsNavContext } from "../Scaffold";
import { BreadTransaction } from "breadcommon";
import dayjs from "dayjs";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { LoadingInitialDataContext } from "../LoadUser";

export function TransactionsScreen(): JSX.Element {
  const transactions = useContext(TransactionsContext);
  const accounts = useContext(AccountsContext);
  const categories = useContext(CategoriesContext);
  const loadingInitialData = useContext(LoadingInitialDataContext);
  const [transactionsNav] = useContext(TransactionsNavContext);

  function applySortOrder(value: number, order: SortOrders) {
    if (order === SortOrders.Ascending) {
      return value;
    }
    return -value;
  }

  function getCompareFunction(
    col: SortColumns,
    order: SortOrders
  ): (a: BreadTransaction, b: BreadTransaction) => number {
    const dateComparisonFunction = (
      a: BreadTransaction,
      b: BreadTransaction
    ) => {
      return applySortOrder(
        dayjs(a.date).valueOf() - dayjs(b.date).valueOf(),
        order
      );
    };

    if (col === SortColumns.Account) {
      return (a: BreadTransaction, b: BreadTransaction) => {
        const accountNameA = accounts.get(a.account_id)?.name;
        const accountNameB = accounts.get(b.account_id)?.name;
        if (accountNameA === accountNameB) return dateComparisonFunction(a, b);
        if (!accountNameA) return applySortOrder(-1, order);
        if (!accountNameB) return applySortOrder(1, order);
        return applySortOrder(accountNameA.localeCompare(accountNameB), order);
      };
    }
    if (col === SortColumns.Merchant) {
      return (a: BreadTransaction, b: BreadTransaction) => {
        if (a.merchant === b.merchant) return dateComparisonFunction(a, b);
        if (!a.merchant) return applySortOrder(-1, order);
        if (!b.merchant) return applySortOrder(1, order);
        return applySortOrder(
          a.merchant.name.localeCompare(b.merchant.name),
          order
        );
      };
    }
    if (col === SortColumns.Description) {
      return (a: BreadTransaction, b: BreadTransaction) => {
        if (a.description === b.description)
          return dateComparisonFunction(a, b);
        if (!a.description) return applySortOrder(-1, order);
        if (!b.description) return applySortOrder(1, order);
        return applySortOrder(
          a.description.localeCompare(b.description),
          order
        );
      };
    }
    if (col === SortColumns.Category) {
      return (a: BreadTransaction, b: BreadTransaction) => {
        if (a.categoryId === b.categoryId) return dateComparisonFunction(a, b);
        if (!a.categoryId) return applySortOrder(-1, order);
        if (!b.categoryId) return applySortOrder(1, order);
        const categoryA = categories.get(a.categoryId)?.name;
        const categoryB = categories.get(b.categoryId)?.name;
        if (!categoryA) return applySortOrder(-1, order);
        if (!categoryB) return applySortOrder(1, order);
        return applySortOrder(categoryA.localeCompare(categoryB), order);
      };
    }
    if (col === SortColumns.Amount) {
      return (a: BreadTransaction, b: BreadTransaction) => {
        if (a.amount === b.amount) return dateComparisonFunction(a, b);
        return applySortOrder(a.amount - b.amount, order);
      };
    }

    return dateComparisonFunction;
  }

  function filterTransactions(
    transactions: BreadTransaction[],
    reviewedFilter: boolean | null
  ): BreadTransaction[] {
    if (reviewedFilter === null) {
      return transactions;
    } else {
      return transactions.filter(t => t.reviewed === reviewedFilter);
    }
  }

  function sortTransactions(
    transactions: BreadTransaction[],
    col: SortColumns,
    order: SortOrders
  ): BreadTransaction[] {
    return transactions.sort(getCompareFunction(col, order));
  }

  const sortedTransactions = sortTransactions(
    filterTransactions(
      Array.from(transactions.values()),
      transactionsNav.reviewedFilter
    ),
    transactionsNav.sortColumn,
    transactionsNav.sortOrder
  );

  let inlineTransactions = sortedTransactions.map((t, i) => (
    <InlineTransaction
      key={t.id}
      transaction={t}
    ></InlineTransaction>
  ));

  if (loadingInitialData.transactions || loadingInitialData.categories) {
    // TODO: add better loading animation
    return <div />;
  }

  return (
    <div>
      <div className={styles.transactionsListContainer}>
        {inlineTransactions}
      </div>
      <div id={styles.endOfTransactionsListContainer}>
        End of transactions list.
      </div>
    </div>
  );
}

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Icon } from "../Icon";
import { HorizontalSpacer, VerticalSpacer } from "../utils/Utils";
import styles from "./ScreenWithLeftNav.module.scss";
import layoutStyles from "../utils/Layout.module.scss";

export type LeftNavOption = {
  name: string;
  iconName: string;
};

function NavOption(props: {
  navOption: LeftNavOption;
  isSelected: boolean;
  handleClick: Function;
}) {
  function getNavOptionClasses() {
    let classes = styles.navOption;
    if (props.isSelected) {
      classes = `${classes} ${styles.selectedNavOption}`;
    }
    return classes;
  }

  return (
    <button
      className={getNavOptionClasses()}
      onClick={() => props.handleClick(props.navOption.name)}
    >
      <div className={`${layoutStyles.flexH} ${layoutStyles.flexMainStart}`}>
        <Icon
          name={props.navOption.iconName}
          size={16}
          fill={props.isSelected}
        ></Icon>
        <HorizontalSpacer width={10}></HorizontalSpacer>
        {props.navOption.name}
      </div>
    </button>
  );
}

function LeftNav(props: {
  navOptions: LeftNavOption[];
  selectedNavOptionName: string;
  handleMenuItemClicked: Function;
  contentIsScrolled: boolean;
}) {
  let i = 0;
  let navOptionsList: JSX.Element[] = [];
  for (const navOption of props.navOptions) {
    i++;
    navOptionsList.push(
      <NavOption
        navOption={navOption}
        isSelected={props.selectedNavOptionName === navOption.name}
        handleClick={props.handleMenuItemClicked}
      />
    );
    if (i !== props.navOptions.length) {
      navOptionsList.push(<VerticalSpacer height={15} />);
    }
  }

  return (
    <div
      className={`${styles.leftNavContainer} ${
        props.contentIsScrolled ? styles.shadow : ""
      }`}
    >
      {navOptionsList}
    </div>
  );
}

export function ScreenWithLeftNav(props: {
  contentIsScrolled: boolean;
  handleScrollForRibbon: (event: Event, resetToNotScrolled?: boolean) => void;
  setNotScrolled: () => void;
  navOptions: LeftNavOption[];
  selectedNavOptionName: string;
  handleMenuItemClicked: Function;
  children: JSX.Element;
}) {
  return (
    <div className={styles.screenContainer}>
      <div className={`${styles.leftContainer}`}>
        <LeftNav
          navOptions={props.navOptions}
          selectedNavOptionName={props.selectedNavOptionName}
          handleMenuItemClicked={props.handleMenuItemClicked}
          contentIsScrolled={props.contentIsScrolled}
        />
      </div>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "move" },
        }}
        className={styles.scrollableRightContainer}
        events={{
          scroll: (_, e) => props.handleScrollForRibbon(e),
          destroyed: (_, e) => props.setNotScrolled(),
        }}
      >
        <div className={`${styles.rightContainer}`}>{props.children}</div>
      </OverlayScrollbarsComponent>
    </div>
  );
}

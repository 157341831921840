import {
  BreadBudget,
  BreadTransaction,
  FirestoreDocCategory,
} from "breadcommon";
import reportTableStyles from "./ReportTable.module.scss";
import { NO_CATEGORY, TOTAL_ROW_KEY, UNREVIEWED } from "./UnderstandingScreen";
import { useContext } from "react";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import dayjs, { Dayjs } from "dayjs";
import { DollarAmount } from "../common/DollarAmount";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const HIDE_ALL_EMPTY_CATEGORIES = false; // TODO add in settings

export type Report = {
  start: Dayjs;
  end: Dayjs;
  title: string;
  // budget information, by category
  budget: BreadBudget | null;
  // The column for category totals
  totals: ReportColumn;
  // The columns for each month
  months: ReportColumn[];
};

export type ReportCell = {
  // The transactions totalled in this cell,
  // for possible future analysis use.
  transactions: BreadTransaction[];
  // Total dollar amount for these transactions.
  total: number;
};

export type ReportColumn = {
  // beginning of date range
  start: Dayjs;
  // end of date range
  end: Dayjs;
  // Pretty label to print at the top of the column
  header: string;
  // Keys are mostly categories, plus "Unreviewed" and the like
  cells: Map<string, ReportCell>;
};

export function newReportCell(): ReportCell {
  return {
    transactions: [],
    total: 0,
  };
}

export function newReportColumn(
  start: Dayjs,
  end: Dayjs,
  row_keys: string[],
  header: string | null = null
): ReportColumn {
  let cells: Map<string, ReportCell> = new Map();
  row_keys.forEach((k) => cells.set(k, newReportCell()));
  return {
    start: start,
    end: end,
    header: header ?? start.format("MMM 'YY"),
    cells: cells,
  };
}

export function newReport(
  start: Dayjs,
  end: Dayjs,
  budget: BreadBudget | null,
  categories: string[],
  transactions: BreadTransaction[]
): Report {
  let row_keys = categories;
  row_keys.push(NO_CATEGORY);
  row_keys.push(UNREVIEWED);
  row_keys.push(TOTAL_ROW_KEY);
  let months: ReportColumn[] = [];
  months.push(newReportColumn(start, start.endOf("month"), row_keys));
  for (
    let d = start.startOf("month").add(1, "month");
    d < end;
    d = d.add(1, "month")
  ) {
    months.push(newReportColumn(d, d.endOf("month"), row_keys));
  }
  let report = {
    start: start,
    end: end,
    title: budget?.name ?? `Year of ${start.format("YYYY")}`,
    budget: budget,
    months: months,
    totals: newReportColumn(start, end, row_keys, "Total"),
  };
  transactions.forEach((transaction: BreadTransaction) => {
    addToReportTable(report, transaction);
  });
  return report;
}

export function addToReportCell(
  cell: ReportCell,
  transaction: BreadTransaction
): void {
  cell.transactions.push(transaction);
  cell.total += transaction.amount;
}

export function addToReportColumn(
  column: ReportColumn,
  row_keys: string[],
  transaction: BreadTransaction
): void {
  row_keys.forEach((row_key) =>
    addToReportCell(column.cells.get(row_key)!, transaction)
  );
}

export function addToReportTable(
  report: Report,
  transaction: BreadTransaction
): void {
  if (transaction.date < report.start || transaction.date > report.end) {
    return;
  }
  const row_key = transaction.reviewed
    ? transaction.categoryId ?? NO_CATEGORY
    : UNREVIEWED;
  addToReportColumn(report.totals, [row_key, TOTAL_ROW_KEY], transaction);
  const month = report.months.find(
    (column) =>
      column.start <= transaction.date && transaction.date <= column.end
  );
  if (!month) {
    throw new Error(
      `ERROR: the transaction ${transaction.id} was in the date range for the report, but not for any of the months`
    );
  }
  addToReportColumn(month, [row_key, TOTAL_ROW_KEY], transaction);
}

export function annualReportTitle(date: Dayjs): string {
  return `Year of ${date.format("YYYY")}`;
}

function getRowHeaderFromRowKey(
  rowKey: string,
  categories: Map<string, FirestoreDocCategory>
) {
  const category = categories.get(rowKey);
  if (!category) {
    return rowKey;
  }
  if (!category.emoji) {
    return category.name;
  }
  return category.emoji + "   " + category.name;
}

export function AnalyzeReportTable(props: { report: Report }): JSX.Element {
  const categories = useContext(CategoriesContext);

  let rowKeysOrder = Array.from(categories.values())
    .sort((c1, c2) => c1.order_position - c2.order_position)
    .map((c) => c.id);
  rowKeysOrder.push(NO_CATEGORY);
  rowKeysOrder.push(UNREVIEWED);
  rowKeysOrder.push(TOTAL_ROW_KEY);

  // hide empty rows
  rowKeysOrder = rowKeysOrder.filter(
    (row_key) =>
      row_key === TOTAL_ROW_KEY ||
      (row_key !== UNREVIEWED &&
        row_key !== NO_CATEGORY &&
        !HIDE_ALL_EMPTY_CATEGORIES) ||
      !props.report.months.every(
        (month) => month.cells.get(row_key)!.total === 0
      )
  );

  const headerColumn = (
    <div className={`${reportTableStyles.column} ${reportTableStyles.header}`}>
      <div
        className={`${reportTableStyles.cell} ${reportTableStyles.header}`}
      />
      {rowKeysOrder.map((row_key) => (
        <div className={specialRowStyle(reportTableStyles.cell, row_key)}>
          {getRowHeaderFromRowKey(row_key, categories)}
        </div>
      ))}
    </div>
  );

  let budgetColumn = null;
  if (props.report.budget !== null) {
    let budgetAmounts = new Map<string, number>();
    const budgetLineItems = props.report.budget!.line_items;
    rowKeysOrder.forEach((row_key) => {
      if (budgetLineItems.has(row_key)) {
        budgetAmounts.set(row_key, budgetLineItems.get(row_key)!.amount);
      }
      if (row_key === TOTAL_ROW_KEY) {
        let total = 0;
        budgetLineItems.forEach((line_item) => (total += line_item.amount));
        budgetAmounts.set(row_key, total);
      }
    });
    budgetColumn = (
      <div className={reportTableStyles.column}>
        <div
          className={`${reportTableStyles.cell} ${reportTableStyles.header}`}
        >
          Budget
        </div>
        {rowKeysOrder.map((row_key) => (
          <div className={specialRowStyle(reportTableStyles.cell, row_key)}>
            {dollarAmount(budgetAmounts.get(row_key) ?? 0)}
          </div>
        ))}
      </div>
    );
  }

  const totalsColumn = (
    <div className={reportTableStyles.column}>
      <div className={`${reportTableStyles.cell} ${reportTableStyles.header}`}>
        {props.report.totals.header}
      </div>
      {rowKeysOrder.map((row_key) => (
        <div className={specialRowStyle(reportTableStyles.cell, row_key)}>
          {dollarAmount(props.report.totals.cells.get(row_key)!.total)}
        </div>
      ))}
    </div>
  );

  const monthsColumns = props.report.months.map((column, i) => (
    <div
      className={`
        ${reportTableStyles.column} 
        ${column.start > dayjs() ? reportTableStyles.future : ""}
      `}
    >
      <div className={`${reportTableStyles.cell} ${reportTableStyles.header}`}>
        {column.header}
      </div>
      {rowKeysOrder.map((row_key) => (
        <div className={specialRowStyle(reportTableStyles.cell, row_key)}>
          {dollarAmount(column.cells.get(row_key)!.total)}
        </div>
      ))}
    </div>
  ));

  return (
    <div className={reportTableStyles.table}>
      <div className={reportTableStyles.frozenColumns}>
        {headerColumn}
        {budgetColumn}
        {totalsColumn}
      </div>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "move" },
        }}
        className={reportTableStyles.scrollbarContainer}
        // events={{
        //   scroll: (_, e) => handleScroll(e),
        // }}
      >
        <div className={reportTableStyles.scrollableColumns}>
          {monthsColumns}
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}

function specialRowStyle(className: string, row_key: string): string {
  if (row_key === UNREVIEWED) {
    return `${className} ${reportTableStyles.unreviewed}`;
  } else if (row_key === TOTAL_ROW_KEY) {
    return `${className} ${reportTableStyles.totalrow}`;
  }
  return className;
}

// pull this out into a helper function so we can easily set formatting in all the cells
function dollarAmount(n: number): JSX.Element {
  // TODO update useParensForNegative to pull from a user preference
  return (
    <DollarAmount
      n={n}
      includeDecimals={false}
      showPlus={true}
      showMinus={true}
      showDollarSign={false}
      monospaceFont={false}
      align="right"
    />
  );
}

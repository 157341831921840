import { FirestoreDocUser } from "breadcommon";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";

// null means logged out
// undefined means initial loading
export const AuthContext = createContext<FirestoreDocUser | null | undefined>(
  undefined
);

export function AuthContextProvider(props: {
  children: JSX.Element;
}): JSX.Element {
  const [auth, setUserAuth] = useState<FirestoreDocUser | null | undefined>(
    undefined
  );

  useEffect(() => {
    const auth = getAuth();
    // We need to initialize with onAuthStateChanged
    // for persistence across sessions to work.
    onAuthStateChanged(auth, (user) => {
      if (user != null) {
        const u: FirestoreDocUser = {
          uid: user.uid,
        };
        setUserAuth(u);
      } else {
        setUserAuth(null);
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
}

import { initializeApp } from "firebase/app";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider, createTheme } from "@mui/material";
import { AuthContextProvider } from "./firebaseio/AuthContext";
import { Router } from "./Router";
import { AuthLoadScreen } from "./AuthLoadScreen";

export function Init(): JSX.Element {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  initializeApp(firebaseConfig);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#7d57ff",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthContextProvider>
          <AuthLoadScreen>
            <Router />
          </AuthLoadScreen>
        </AuthContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

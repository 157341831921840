export function VerticalSpacer(props: { height: number }): JSX.Element {
  return <div style={{ height: `${props.height}px` }} />;
}

export function HorizontalSpacer(props: { width: number }): JSX.Element {
  return <div style={{ width: `${props.width}px` }} />;
}

export function debounce(fn: Function, ms = 100) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}

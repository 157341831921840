import styles from "./AuthScreen.module.scss";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useContext } from "react";
import { AuthScreenContext, AuthScreens } from "./AuthScreen";
import google_logo from "../images/google_logo.png";
import { Icon } from "../Icon";
import {
  Header,
  FooterFiller,
  BoxesVerticalSpacer,
  LogoButtonTextHorizontalSpacer,
  SmallNavButtonsFirstVerticalSpacer,
  CreateAccountInsteadButton,
} from "./commonComponents";

async function submitLogInWithGoogle() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  try {
    await signInWithPopup(auth, provider);
  } catch (error) {
    console.log(error);
  }
}

export function LoginOptionsScreen(): JSX.Element {
  const [, setAuthScreen] = useContext(AuthScreenContext);

  // TODO: Create local state to track if we are attempting to log in
  // and if log in failed so we can dynamcally update the UI with
  // loading & red/error feedback.

  return (
    <div className={styles.authScreenContainer}>
      <Header title={"Sign in"}></Header>
      <div className={styles.authFormContainer}>
        <button
          className={`${styles.authButton} ${styles.signInOptionButton}`}
          onClick={() => submitLogInWithGoogle()}
        >
          <img
            src={google_logo}
            className={styles.googleLogo}
            alt="Google logo"
          ></img>
          <LogoButtonTextHorizontalSpacer></LogoButtonTextHorizontalSpacer>
          Sign in with Google
        </button>
        <BoxesVerticalSpacer></BoxesVerticalSpacer>
        <button
          className={`${styles.authButton} ${styles.signInOptionButton}`}
          onClick={() => setAuthScreen(AuthScreens.EmailLogin)}
        >
          <Icon name={"mail"} size={20} className={styles.emailIcon}></Icon>
          <LogoButtonTextHorizontalSpacer></LogoButtonTextHorizontalSpacer>
          Sign in with email
        </button>
        <SmallNavButtonsFirstVerticalSpacer></SmallNavButtonsFirstVerticalSpacer>
        <CreateAccountInsteadButton></CreateAccountInsteadButton>
      </div>
      <FooterFiller></FooterFiller>
    </div>
  );
}

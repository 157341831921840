import { useEffect, useState } from "react";
import { createContextWithSetter } from "../utils/ContextUtils";
import { AccessCodeScreen } from "./AccessCodeScreen";
import { LoginOptionsScreen } from "./LoginOptionsScreen";
import { EmailCreateAccountScreen } from "./EmailCreateAccountScreen";
import { EmailLoginScreen } from "./EmailLoginScreen";
import { CreateAccountOptionsScreen } from "./CreateAccountOptionsScreen";
import { matchPath, useLocation } from "react-router-dom";
import { FontLoad } from "../FontLoad";

export enum AuthScreens {
  LoginOptions,
  EmailLogin,
  CreateAccountOptions,
  EmailCreateAccount,
  AccessCode,
}

export const AuthScreenContext = createContextWithSetter<AuthScreens>(
  AuthScreens.LoginOptions
);

function getAuthScreen(authScreenState: AuthScreens): JSX.Element {
  switch (authScreenState) {
    case AuthScreens.AccessCode:
      return <AccessCodeScreen></AccessCodeScreen>;
    case AuthScreens.LoginOptions:
      return <LoginOptionsScreen></LoginOptionsScreen>;
    case AuthScreens.EmailLogin:
      return <EmailLoginScreen></EmailLoginScreen>;
    case AuthScreens.CreateAccountOptions:
      return <CreateAccountOptionsScreen></CreateAccountOptionsScreen>;
    case AuthScreens.EmailCreateAccount:
      return <EmailCreateAccountScreen></EmailCreateAccountScreen>;
    default:
      return <LoginOptionsScreen></LoginOptionsScreen>;
  }
}

function locationToAuthScreen(path: string): AuthScreens {
  const urlMatch = matchPath("/auth/:route", path);

  if (urlMatch?.params.route === "signup") {
    return AuthScreens.CreateAccountOptions;
  }
  return AuthScreens.LoginOptions;
}

export function AuthScreen(): JSX.Element {
  const [authScreen, setAuthScreen] = useState(AuthScreens.LoginOptions);

  const location = useLocation();

  useEffect(() => {
    const newAuthScreen = locationToAuthScreen(location.pathname);
    setAuthScreen(newAuthScreen);
  }, [location]);

  return (
    <AuthScreenContext.Provider value={[authScreen, setAuthScreen]}>
      {getAuthScreen(authScreen)}
    </AuthScreenContext.Provider>
  );
}

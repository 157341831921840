import * as z from "zod";

export const firestoreDocPlaidItemSchema = z.object({
  plaid_item_id: z.string(),
  access_token: z.string(),
  created_timestamp_secs: z.number(),
  transaction_cursor: z.string().nullable(),
  investment_transaction_last_sync_timestamp_secs: z.number().optional(),
});

export type FirestoreDocPlaidItem = z.infer<typeof firestoreDocPlaidItemSchema>;

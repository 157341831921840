import styles from "./AuthScreen.module.scss";
import { KeyboardEvent, useContext, useRef, useState } from "react";
import { AuthScreenContext, AuthScreens } from "./AuthScreen";
import {
  BoxesVerticalSpacer,
  ErrorMsgBanner,
  FooterFiller,
  Header,
  SignInInsteadButton,
  SmallNavButtonsFirstVerticalSpacer,
} from "./commonComponents";
import { keyCodes } from "../utils/KeyCodes";

export function AccessCodeScreen(): JSX.Element {
  const [accessCodeError, setAccessCodeError] = useState<string | null>(null);
  const [, setAuthScreen] = useContext(AuthScreenContext);
  const codeRef = useRef<HTMLInputElement>(null);

  const ACCESS_CODE = "SOURDOUGH";
  const INCORRECT_ACCESS_CODE_ERROR =
    "Incorrect access code. \nPlease try again.";

  function submitAccessCode() {
    setAccessCodeError(null);
    if (codeRef.current?.value.toUpperCase() === ACCESS_CODE) {
      setAuthScreen(AuthScreens.CreateAccountOptions);
    } else {
      setAccessCodeError(INCORRECT_ACCESS_CODE_ERROR);
    }
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.code === keyCodes.ENTER && !e.repeat) {
      submitAccessCode();
    }
  }

  return (
    <div className={styles.authScreenContainer}>
      <Header title={"Create an account"}></Header>
      <div className={styles.authFormContainer}>
        <input
          className={`${styles.authInput} ${styles.accessCodeInput}`}
          placeholder="Beta Access Code"
          ref={codeRef}
          autoFocus
          onKeyDown={(e) => handleKeyDown(e)}
        ></input>
        <BoxesVerticalSpacer></BoxesVerticalSpacer>
        <button className={styles.authButton} onClick={submitAccessCode}>
          Submit
        </button>
        <ErrorMsgBanner msg={accessCodeError}></ErrorMsgBanner>
        <SmallNavButtonsFirstVerticalSpacer></SmallNavButtonsFirstVerticalSpacer>
        <SignInInsteadButton></SignInInsteadButton>
      </div>
      <FooterFiller></FooterFiller>
    </div>
  );
}

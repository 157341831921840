import styles from "./AuthScreen.module.scss";
import { HorizontalSpacer, VerticalSpacer } from "../utils/Utils";
import bread_logo from "../images/bread_logo.png";
import { useContext } from "react";
import { AuthScreenContext, AuthScreens } from "./AuthScreen";

export function Header(props: { title: string }): JSX.Element {
  return (
    <div className={styles.headerContainer}>
      <img src={bread_logo} className={styles.breadLogo} alt="Bread logo"></img>
      <VerticalSpacer height={15}></VerticalSpacer>
      <div className={styles.headerTitle}>{props.title}</div>
      <VerticalSpacer height={80}></VerticalSpacer>
    </div>
  );
}

export function FooterFiller() {
  return <div className={styles.footerFiller}></div>;
}

export function CreateAccountInsteadButton() {
  const [, setAuthScreen] = useContext(AuthScreenContext);
  return (
    <div className={styles.authScreenSwitchTextContainer}>
      <div>New to Bread?</div>
      <HorizontalSpacer width={7} />
      <div
        className={styles.authScreenSwitchTextButton}
        onClick={() => setAuthScreen(AuthScreens.AccessCode)}
      >
        Create an account
      </div>
    </div>
  );
}

export function SignInInsteadButton() {
  const [, setAuthScreen] = useContext(AuthScreenContext);
  return (
    <div className={styles.authScreenSwitchTextContainer}>
      <div>Already have an account?</div>
      <HorizontalSpacer width={7} />
      <div
        className={styles.authScreenSwitchTextButton}
        onClick={() => setAuthScreen(AuthScreens.LoginOptions)}
      >
        Sign in
      </div>
    </div>
  );
}

export function SignInADifferentWayButton() {
  const [, setAuthScreen] = useContext(AuthScreenContext);
  return (
    <div className={styles.authScreenSwitchTextContainer}>
      <div
        className={styles.authScreenSwitchTextButton}
        onClick={() => setAuthScreen(AuthScreens.LoginOptions)}
      >
        Sign in a different way
      </div>
    </div>
  );
}

export function ErrorMsgBanner(props: { msg: string | null }) {
  if (props.msg) {
    return (
      <div>
        <VerticalSpacer height={50}></VerticalSpacer>
        <div className={styles.errorMsgBanner}>{props.msg}</div>
      </div>
    );
  }
  return null;
}

export function CreateAccountADifferentWayButton() {
  const [, setAuthScreen] = useContext(AuthScreenContext);
  return (
    <div className={styles.authScreenSwitchTextContainer}>
      <div
        className={styles.authScreenSwitchTextButton}
        onClick={() => setAuthScreen(AuthScreens.CreateAccountOptions)}
      >
        Create account a different way
      </div>
    </div>
  );
}

export function BoxesVerticalSpacer() {
  return <VerticalSpacer height={15}></VerticalSpacer>;
}

export function LogoButtonTextHorizontalSpacer() {
  return <HorizontalSpacer width={15}></HorizontalSpacer>;
}

export function SmallNavButtonsFirstVerticalSpacer() {
  return <VerticalSpacer height={80}></VerticalSpacer>;
}

export function MultipleSmallNavButtonsVerticalSpacer() {
  return <VerticalSpacer height={15}></VerticalSpacer>;
}

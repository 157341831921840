import { useNavigate } from "react-router-dom";
import styles from "./Footer.module.scss";

export function Footer() {
  const navigate = useNavigate();

  return (
    <div className={styles.footerContainer}>
      <div
        className={styles.privacyPolicyLink}
        onClick={() => navigate("/privacy")}
      >
        Privacy Policy
      </div>
      Copyright © 2024 Tappen LLC
    </div>
  );
}

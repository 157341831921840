import styles from "./Header.module.scss";
import breadLogo from "../images/bread_logo.png";
import { useNavigate } from "react-router-dom";

export function Header() {
  const navigate = useNavigate();

  return (
    <div className={styles.headerContainer}>
      <div className={styles.spacingContainer}>
        <div className={styles.nameAndLogo} onClick={() => navigate("/")}>
          <img className={styles.logoImg} src={breadLogo} alt="Bread Logo" />
          Bread
        </div>
        <div>
          <button
            className={styles.signInButton}
            onClick={() => navigate("/auth")}
          >
            Sign in
          </button>
          <button
            className={`${styles.signUpButton} ${styles.desktopOnly}`}
            onClick={() => navigate("/auth/signup")}
          >
            Sign up
          </button>
        </div>
      </div>
    </div>
  );
}

import * as z from "zod";

// From https://plaid.com/docs/api/institutions/#institutionsget_by_id
const plaidDataInstitutionSchema = z.object({
  institution_id: z.string(),
  name: z.string(),
  products: z.array(z.string()),
  country_codes: z.array(z.string()),
  url: z.string().nullable().optional(),
  primary_color: z.string().nullable().optional(),
  logo: z.string().nullable().optional(),
  routing_numbers: z.array(z.string()),
  dtc_numbers: z.array(z.string()).optional(),
  oauth: z.boolean(),
});

export const firestoreDocInstitutionSchema = z.object({
  plaid_data: plaidDataInstitutionSchema.nullable(),
});

export type FirestoreDocInstitution = z.infer<
  typeof firestoreDocInstitutionSchema
>;

import { getApp } from "firebase/app";
import {
  HttpsCallableResult,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

type FirebaseFunctionName =
  | "getplaidlinktoken"
  | "exchangepublictoken"
  | "syncaccounts"
  | "synctransactions"
  | "removeunuseditemsandinstitutions";

function callFirebaseFunction(
  functionName: FirebaseFunctionName,
  args: any
): Promise<HttpsCallableResult<unknown>> {
  const app = getApp();
  const functions = getFunctions(app);
  const firebaseFunction = httpsCallable(functions, functionName);
  return firebaseFunction(args);
}

export function getPlaidLinkToken(): Promise<HttpsCallableResult<unknown>> {
  return callFirebaseFunction("getplaidlinktoken", {});
}

export function exchangePublicToken(
  publicToken: string
): Promise<HttpsCallableResult<unknown>> {
  return callFirebaseFunction("exchangepublictoken", {
    public_token: publicToken,
  });
}

export function syncAccounts(): Promise<HttpsCallableResult<unknown>> {
  return callFirebaseFunction("syncaccounts", {});
}

export function syncTransactions(): Promise<HttpsCallableResult<unknown>> {
  return callFirebaseFunction("synctransactions", {});
}

export function removeUnusedItemsAndInstitutions(): Promise<
  HttpsCallableResult<unknown>
> {
  return callFirebaseFunction("removeunuseditemsandinstitutions", {});
}

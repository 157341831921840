import styles from "./AuthScreen.module.scss";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { KeyboardEvent, useRef, useState } from "react";
import {
  BoxesVerticalSpacer,
  CreateAccountADifferentWayButton,
  ErrorMsgBanner,
  FooterFiller,
  Header,
  MultipleSmallNavButtonsVerticalSpacer,
  SignInInsteadButton,
  SmallNavButtonsFirstVerticalSpacer,
} from "./commonComponents";
import { firestoreCreateUser } from "../firebaseio/firestoreIo";
import { errorToMsg } from "../utils/ErrorToMsg";
import { keyCodes } from "../utils/KeyCodes";

export function EmailCreateAccountScreen(): JSX.Element {
  const [attemptingAccountCreation, setAttemptingAccountCreation] =
    useState(false);
  const [createAccountError, setCreateAccountError] = useState<string | null>(
    null
  );
  const emailRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);
  const pwConfirmRef = useRef<HTMLInputElement>(null);

  async function submitCreateAccount(): Promise<void> {
    const email = emailRef.current?.value ?? "";
    const pw = pwRef.current?.value ?? "";
    const pwConfirm = pwConfirmRef.current?.value ?? "";

    setAttemptingAccountCreation(true);
    if (pw !== pwConfirm) {
      setCreateAccountError(
        "Please ensure the two password fields match and then try again."
      );
      setAttemptingAccountCreation(false);
      return;
    }

    const auth = getAuth();

    try {
      let userCreds = await createUserWithEmailAndPassword(auth, email, pw);
      await firestoreCreateUser(userCreds.user.uid);
    } catch (error) {
      setCreateAccountError(errorToMsg(error));
    }
    setAttemptingAccountCreation(false);
  }

  let createAccountButtonDisplay: string | JSX.Element = "Create account";
  if (attemptingAccountCreation) {
    createAccountButtonDisplay = (
      <l-line-wobble size="150" speed="2" color="#fbfbfc"></l-line-wobble>
    );
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.code === keyCodes.ENTER && !e.repeat) {
      submitCreateAccount();
    }
  }

  return (
    <div className={styles.authScreenContainer}>
      <Header title={"Create an account"}></Header>
      <div className={styles.authFormContainer}>
        <input
          className={styles.authInput}
          placeholder="email"
          type="email"
          ref={emailRef}
          autoFocus
          onKeyDown={(e) => handleKeyDown(e)}
        ></input>
        <BoxesVerticalSpacer></BoxesVerticalSpacer>
        <input
          className={styles.authInput}
          placeholder="password"
          type="password"
          ref={pwRef}
          onKeyDown={(e) => handleKeyDown(e)}
        ></input>
        <BoxesVerticalSpacer></BoxesVerticalSpacer>
        <input
          className={styles.authInput}
          placeholder="confirm password"
          type="password"
          ref={pwConfirmRef}
          onKeyDown={(e) => handleKeyDown(e)}
        ></input>
        <BoxesVerticalSpacer></BoxesVerticalSpacer>
        <button
          className={styles.authButton}
          onClick={() => submitCreateAccount()}
          disabled={attemptingAccountCreation}
        >
          {createAccountButtonDisplay}
        </button>
        <ErrorMsgBanner msg={createAccountError}></ErrorMsgBanner>
        <SmallNavButtonsFirstVerticalSpacer></SmallNavButtonsFirstVerticalSpacer>
        <SignInInsteadButton></SignInInsteadButton>
        <MultipleSmallNavButtonsVerticalSpacer></MultipleSmallNavButtonsVerticalSpacer>
        <CreateAccountADifferentWayButton></CreateAccountADifferentWayButton>
      </div>
      <FooterFiller></FooterFiller>
    </div>
  );
}

export const keyCodes = {
  ENTER: "Enter",
  BACKSPACE: "Backspace",
  UPARROW: "ArrowUp",
  DOWNARROW: "ArrowDown",
  ESC: "Escape",
  TAB: "Tab",
};

// See https://www.w3.org/TR/uievents-code/#key-alphanumeric-section
export function getLetterOrNumberFromCode(code: string): string | null {
  if (code.startsWith("Key")) {
    return code.replace("Key", "");
  }
  if (code.startsWith("Digit")) {
    return code.replace("Digit", "");
  }
  return null;
}

import { Ribbon } from "./Ribbon";
import styles from "./Scaffold.module.scss";
import { TransactionsScreen } from "./transactions_screen/TransactionsScreen";
import { useContext, useState } from "react";
import { BudgetScreen } from "./budget_screen/BudgetScreen";
import { createContextWithSetter } from "./utils/ContextUtils";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { SettingsScreen } from "./settings_screen/SettingsScreen";
import { MainNavScreens } from "breadcommon";
import { UrlContext } from "./UrlContext";
import { UnderstandingScreen } from "./understanding_screen/UnderstandingScreen";

export enum SortColumns {
  Date,
  Account,
  Merchant,
  Description,
  Category,
  Amount,
}

export enum SortOrders {
  Ascending,
  Descending,
}

export type TransactionsNav = {
  sortColumn: SortColumns;
  sortOrder: SortOrders;
  reviewedFilter: boolean | null;
};

const defaultTransactionsNav = {
  sortColumn: SortColumns.Date,
  sortOrder: SortOrders.Descending,
  reviewedFilter: null,
};

export const TransactionsNavContext = createContextWithSetter<TransactionsNav>(
  defaultTransactionsNav
);

export function Scaffold(): JSX.Element {
  const urlInfo = useContext(UrlContext);
  const [contentIsScrolled, setContentIsScrolled] = useState(false);
  const [transactionsNav, setTransactionsNav] = useState<TransactionsNav>(
    defaultTransactionsNav
  );

  // const currentTab = urlInfo.currentTab ?? preferences.web.home_screen;
  const currentTab = urlInfo.currentTab ?? MainNavScreens.TRANSACTIONS;

  function handleScroll(event: Event) {
    if (!event.target) {
      return;
    }

    const htmlElem = event.target as HTMLElement;

    // We  debounce this so we don't set state every time we scroll,
    // only when the state actually changes.
    if (htmlElem.scrollTop > 0 && !contentIsScrolled) {
      setContentIsScrolled(true);
      return;
    }
    if (htmlElem.scrollTop <= 0 && contentIsScrolled) {
      setContentIsScrolled(false);
    }
  }

  function mainNavToScreen(mainNav: MainNavScreens): JSX.Element {
    switch (mainNav) {
      case MainNavScreens.ACCOUNTS:
        return <div>TODO: remove accounts option</div>;
      case MainNavScreens.TRANSACTIONS:
        return <TransactionsScreen></TransactionsScreen>;
      case MainNavScreens.UNDERSTANDING:
        return (
          <UnderstandingScreen
            contentIsScrolled={contentIsScrolled}
            handleScrollForRibbon={handleScroll}
            setNotScrolled={() => setContentIsScrolled(false)}
          ></UnderstandingScreen>
        );
      case MainNavScreens.BUDGET:
        return <BudgetScreen></BudgetScreen>;
      case MainNavScreens.SETTINGS:
        return (
          <SettingsScreen
            contentIsScrolled={contentIsScrolled}
            handleScrollForRibbon={handleScroll}
            setNotScrolled={() => setContentIsScrolled(false)}
          ></SettingsScreen>
        );
    }
  }

  const ribbonAndContent = (
    <OverlayScrollbarsComponent
      options={{
        scrollbars: { autoHide: "never" },
      }}
    >
      <div id="main" className={styles.screenContainer}>
        <Ribbon floating={contentIsScrolled}></Ribbon>
        {/* options described at https://kingsora.github.io/OverlayScrollbars/ */}
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: "move" },
          }}
          className={styles.mainContentContainer}
          events={{
            scroll: (_, e) => handleScroll(e),
          }}
        >
          {mainNavToScreen(currentTab)}
        </OverlayScrollbarsComponent>
      </div>
    </OverlayScrollbarsComponent>
  );

  if (MainNavScreens.TRANSACTIONS) {
    return (
      <TransactionsNavContext.Provider
        value={[transactionsNav, setTransactionsNav]}
      >
        {ribbonAndContent}
      </TransactionsNavContext.Provider>
    );
  }

  return ribbonAndContent;
}

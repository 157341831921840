import { Icon } from "./Icon";
import bread_logo from "./images/bread_logo.png";
import styles from "./FontLoad.module.scss";
import { lineWobble } from "ldrs";
import { useEffect, useState } from "react";

// https://uiball.com/ldrs/ are web components that must be registered before
// use
lineWobble.register();

// A screen to show while we wait for the icons font to load.
export function FontLoad(props: { children: JSX.Element }): JSX.Element {
  const [isDefaultAppLoadPeriod, setIsDefaultAppLoadPeriod] =
    useState<boolean>(true);
  const [appLoading, setAppLoading] = useState<boolean>(true);

  // Wait for the icons font to load.
  // Otherwise they initially render as text and it looks pretty gross.
  useEffect(() => {
    document.fonts.ready.then(() => setAppLoading(false));
  }, []);

  setTimeout(() => {
    setIsDefaultAppLoadPeriod(false);
  }, 1000);

  // Timout on waiting for the icons font to load.
  setTimeout(() => {
    setAppLoading(false);
  }, 60000);

  if (isDefaultAppLoadPeriod || appLoading) {
    return (
      <div className={styles.container}>
        <img
          src={bread_logo}
          className={styles.breadLogo}
          alt="Bread logo"
        ></img>
        <Icon name={"home"} size={0} className={styles.dummyIcon}></Icon>
        <div className={styles.loadingBarContainer}>
          {!isDefaultAppLoadPeriod ? (
            <l-line-wobble size="130" speed="2" color="#7d57ff"></l-line-wobble>
          ) : null}
        </div>
      </div>
    );
  }

  return props.children;
}

import reportTableStyles from "./ReportTable.module.scss";
import { useContext, useEffect, useState } from "react";
import { LoadingInitialDataContext } from "../LoadUser";
import { Skeleton } from "@mui/material";
import { BudgetsContext } from "../firebaseio/BudgetsContext";
import { ScreenWithLeftNav } from "../common/ScreenWithLeftNav";
import { BalancesScreen } from "./BalancesScreen";
import { BudgetTrackingScreen } from "./BudgetTrackingScreen";
import { SliceAndDiceScreen } from "./SliceAndDiceScreen";
import { createContextWithSetter } from "../utils/ContextUtils";

export const UNREVIEWED = "Unreviewed"; // row_key and label for "unreviewed" category row
export const NO_CATEGORY = "No Category"; // row_key and label for reviewed transactions with no category set
export const TOTAL_ROW_KEY = "Total"; // the row_key and label for the total across all categories

export type UnderstandingNavScreens =
  | "Balances"
  | "Budget Tracking"
  | "Slice & Dice";

export type BudgetAnalysisState = {
  selectedBudgetId: string;
};

export const BudgetAnalysisStateContext =
  createContextWithSetter<BudgetAnalysisState>({ selectedBudgetId: "" });

export function UnderstandingScreen(props: {
  contentIsScrolled: boolean;
  handleScrollForRibbon: (event: Event, resetToNotScrolled?: boolean) => void;
  setNotScrolled: () => void;
}): JSX.Element {
  const [understandingNavScreen, setUnderstandingNavScreen] =
    useState<UnderstandingNavScreens>("Budget Tracking");
  const [budgetAnalysisState, setBudgetAnalysisState] =
    useState<BudgetAnalysisState>({ selectedBudgetId: "" });
  const budgets = useContext(BudgetsContext);
  const loadingInitialData = useContext(LoadingInitialDataContext);

  useEffect(() => {
    const firstBudget = Array.from(budgets.values()).reverse()[0];
    if (!budgetAnalysisState.selectedBudgetId && firstBudget) {
      setBudgetAnalysisState((prevState) => {
        return {
          ...prevState,
          selectedBudgetId: firstBudget.id,
        };
      });
    }
    // we can't just depend on budgets because that doesn't "change" when the map changes
  }, [budgetAnalysisState.selectedBudgetId, budgets, budgets.size]);

  function getUnderstandingScreen() {
    switch (understandingNavScreen) {
      case "Balances":
        return <BalancesScreen />;
      case "Budget Tracking":
        return (
          <BudgetAnalysisStateContext.Provider
            value={[budgetAnalysisState, setBudgetAnalysisState]}
          >
            <BudgetTrackingScreen />
          </BudgetAnalysisStateContext.Provider>
        );
      case "Slice & Dice":
        return <SliceAndDiceScreen />;
    }
  }

  if (
    loadingInitialData.transactions ||
    loadingInitialData.categories ||
    loadingInitialData.budgets
  ) {
    return (
      <div className={reportTableStyles.accountsScreenContainer}>
        <Skeleton animation="wave" />
      </div>
    );
  }

  return (
    <ScreenWithLeftNav
      contentIsScrolled={props.contentIsScrolled}
      handleScrollForRibbon={props.handleScrollForRibbon}
      setNotScrolled={props.setNotScrolled}
      navOptions={[
        { name: "Budget Tracking", iconName: "balance" },
        { name: "Slice & Dice", iconName: "restaurant_menu" },
        { name: "Balances", iconName: "savings" },
      ]}
      selectedNavOptionName={understandingNavScreen}
      handleMenuItemClicked={setUnderstandingNavScreen}
    >
      {getUnderstandingScreen()}
    </ScreenWithLeftNav>
  );
}

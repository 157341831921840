import { useEffect, useState } from "react";
import { Scaffold } from "./Scaffold";
import { FirestoreDocUser } from "breadcommon";
import { AccountsContextProvider } from "./firebaseio/AccountsContext";
import { InstitutionsContextProvider } from "./firebaseio/InstitutionsContext";
import { UserContextProvider } from "./firebaseio/UserContext";
import { CategoriesContextProvider } from "./firebaseio/CategoriesContext";
import { TransactionsContextProvider } from "./firebaseio/TransactionsContext";
import { createContext } from "react";
import { RulesContextProvider } from "./firebaseio/RulesContext";
import { BudgetsContextProvider } from "./firebaseio/BudgetsContext";
import { PreferencesContextProvider } from "./firebaseio/PreferencesContext";
import {
  setInitialPreferences,
  preferencesExists,
} from "./firebaseio/preferencesIo";
import { FontLoad } from "./FontLoad";

export const LoadingInitialDataContext = createContext<{
  preferences: boolean;
  transactions: boolean;
  accounts: boolean;
  institutions: boolean;
  categories: boolean;
  budgets: boolean;
  rules: boolean;
}>({
  preferences: true,
  transactions: true,
  accounts: true,
  institutions: true,
  categories: true,
  budgets: true,
  rules: true,
});

export function LoadUser(props: { user: FirestoreDocUser }): JSX.Element {
  const [loadingInitialData, setLoadingInitialData] = useState({
    preferences: true,
    transactions: true,
    accounts: true,
    institutions: true,
    categories: true,
    budgets: true,
    rules: true,
  });

  function markInitialLoadComplete(
    dataset: keyof typeof loadingInitialData
  ): void {
    if (loadingInitialData[dataset]) {
      setLoadingInitialData((loadingInitialData) => {
        return {
          ...loadingInitialData,
          [dataset]: false,
        };
      });
    }
  }

  useEffect(() => {
    const ensurepreferencesExists = async () => {
      const preferencesExistsInFirestore = await preferencesExists(
        props.user.uid
      );
      if (!preferencesExistsInFirestore) {
        await setInitialPreferences(props.user.uid);
      }
    };
    ensurepreferencesExists();
  }, [props.user.uid]);

  return (
    <FontLoad>
      <LoadingInitialDataContext.Provider value={loadingInitialData}>
        <UserContextProvider user={props.user}>
          <PreferencesContextProvider
            user={props.user}
            markInitialLoadComplete={() =>
              markInitialLoadComplete("preferences")
            }
          >
            <TransactionsContextProvider
              user={props.user}
              markInitialLoadComplete={() =>
                markInitialLoadComplete("transactions")
              }
            >
              <AccountsContextProvider
                user={props.user}
                markInitialLoadComplete={() =>
                  markInitialLoadComplete("accounts")
                }
              >
                <InstitutionsContextProvider
                  user={props.user}
                  markInitialLoadComplete={() =>
                    markInitialLoadComplete("institutions")
                  }
                >
                  <CategoriesContextProvider
                    user={props.user}
                    markInitialLoadComplete={() =>
                      markInitialLoadComplete("categories")
                    }
                  >
                    <BudgetsContextProvider
                      user={props.user}
                      markInitialLoadComplete={() =>
                        markInitialLoadComplete("budgets")
                      }
                    >
                      <RulesContextProvider
                        user={props.user}
                        markInitialLoadComplete={() =>
                          markInitialLoadComplete("rules")
                        }
                      >
                        <Scaffold></Scaffold>
                      </RulesContextProvider>
                    </BudgetsContextProvider>
                  </CategoriesContextProvider>
                </InstitutionsContextProvider>
              </AccountsContextProvider>
            </TransactionsContextProvider>
          </PreferencesContextProvider>
        </UserContextProvider>
      </LoadingInitialDataContext.Provider>
    </FontLoad>
  );
}

import ribbonStyles from "../Ribbon.module.scss";
import layoutStyles from "../utils/Layout.module.scss";
import { Icon } from "../Icon";
import { useContext, useEffect, useState } from "react";
import {
  syncAccounts,
  syncTransactions,
} from "../firebaseio/firebaseFunctions";
import { firestoreUpdateTransactionsAgainstRules } from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";

export function SyncTransactionsRibbonButton(): JSX.Element {
  const user = useContext(UserContext);
  const [transactionSyncInProgress, setTransationSyncInProgress] =
    useState<boolean>(false);

  async function syncTransactionsAndAccounts() {
    setTransationSyncInProgress(true);
    const transactionsPromise = syncTransactions();
    const accountsPromise = syncAccounts();
    try {
      await Promise.allSettled([transactionsPromise, accountsPromise]);
    } catch (error) {
      console.log(`Syncing encountered an error: ${error}`);
    }
    setTransationSyncInProgress(false);
  }

  useEffect(() => {
    syncTransactionsAndAccounts();
  }, []);

  return (
    <button
      className={`${ribbonStyles.syncButton}`}
      onClick={() => {
        firestoreUpdateTransactionsAgainstRules(user.uid);
        syncTransactionsAndAccounts();
      }}
      disabled={transactionSyncInProgress}
    >
      <div className={layoutStyles.flexH}>
        <Icon
          name="sync"
          size={21}
          className={
            transactionSyncInProgress ? `${ribbonStyles.spinning}` : ""
          }
        ></Icon>
      </div>
    </button>
  );
}

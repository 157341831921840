import styles from "./AuthScreen.module.scss";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { KeyboardEvent, useRef, useState } from "react";
import {
  BoxesVerticalSpacer,
  CreateAccountInsteadButton,
  ErrorMsgBanner,
  FooterFiller,
  Header,
  MultipleSmallNavButtonsVerticalSpacer,
  SignInADifferentWayButton,
  SmallNavButtonsFirstVerticalSpacer,
} from "./commonComponents";
import { lineWobble } from "ldrs";
import { errorToMsg } from "../utils/ErrorToMsg";
import { keyCodes } from "../utils/KeyCodes";

// https://uiball.com/ldrs/ are web components that must be registered before
// use
lineWobble.register();

export function EmailLoginScreen(): JSX.Element {
  const [attemptingLogIn, setAttemptingLogIn] = useState(false);
  const [logInError, setLogInError] = useState<string | null>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);

  async function submitLogInWithUsernameAndPassword() {
    const email = emailRef.current ? emailRef.current.value : "";
    const pw = pwRef.current ? pwRef.current.value : "";
    setAttemptingLogIn(true);
    setLogInError(null);
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, pw);
    } catch (error) {
      setLogInError(errorToMsg(error));
    }
    setAttemptingLogIn(false);
  }

  let signInButtonDisplay: string | JSX.Element = "Sign in";
  if (attemptingLogIn) {
    signInButtonDisplay = (
      <l-line-wobble size="150" speed="2" color="#fbfbfc"></l-line-wobble>
    );
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.code === keyCodes.ENTER && !e.repeat) {
      submitLogInWithUsernameAndPassword();
    }
  }

  return (
    <div className={styles.authScreenContainer}>
      <Header title={"Sign in"}></Header>
      <div className={styles.authFormContainer}>
        <input
          className={styles.authInput}
          placeholder="email"
          type="email"
          ref={emailRef}
          autoFocus
          onKeyDown={(e) => handleKeyDown(e)}
        ></input>
        <BoxesVerticalSpacer></BoxesVerticalSpacer>
        <input
          className={styles.authInput}
          placeholder="password"
          type="password"
          ref={pwRef}
          onKeyDown={(e) => handleKeyDown(e)}
        ></input>
        <BoxesVerticalSpacer></BoxesVerticalSpacer>
        <button
          className={styles.authButton}
          onClick={() => submitLogInWithUsernameAndPassword()}
          disabled={attemptingLogIn}
        >
          {signInButtonDisplay}
        </button>
        <ErrorMsgBanner msg={logInError}></ErrorMsgBanner>
        <SmallNavButtonsFirstVerticalSpacer></SmallNavButtonsFirstVerticalSpacer>
        <CreateAccountInsteadButton></CreateAccountInsteadButton>
        <MultipleSmallNavButtonsVerticalSpacer></MultipleSmallNavButtonsVerticalSpacer>
        <SignInADifferentWayButton></SignInADifferentWayButton>
      </div>
      <FooterFiller></FooterFiller>
    </div>
  );
}

import * as z from "zod";

export const firestoreDocUserSchema = z.object({
  uid: z.string(),
});

/**
 * The type of a user document in firestore
 */
export type FirestoreDocUser = z.infer<typeof firestoreDocUserSchema>;

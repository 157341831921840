import styles from "./ReviewedIcon.module.scss"
import { Icon, IconProps } from "../Icon";

/**
 * Just the little icon for whether something is reviewed or not.
 * If `reviewed` is null, use the grey checkmark.
 */
export function ReviewedIcon({ reviewed, ...restProps }: {
  reviewed: boolean | null;
} & Partial<IconProps>) {
  if (reviewed === true) {
    return <Icon
      className={`${restProps.className} ${styles.reviewedIcon}`}
      name={"done"}
      size={22}
      {...restProps}
    />;
  } else if (reviewed === false) {
    return <Icon
      className={`${restProps.className} ${styles.unreviewedIcon}`}
      name={"circle"}
      size={10}
      fill={true}
      {...restProps}
    />;
  } else {
    return <Icon
      className={`${restProps.className} ${styles.greyIcon}`}
      name={"done"}
      size={22}
      {...restProps}
    />;
  }

}
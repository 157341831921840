import { useNavigate } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "./Header";

import styles from "./LandingPage.module.scss";
import { useEffect, useMemo, useState } from "react";
import React from "react";

function Cursor(props: { showCursor: boolean }) {
  return (
    <span
      className={`${styles.hookCursor} ${
        props.showCursor ? "" : styles.cursorInvisible
      }`}
    >
      |
    </span>
  );
}

enum WritingPhase {
  WRITING,
  DELETING,
  PAUSING,
}

function WrittenEnding() {
  const PHRASES = useMemo(
    () => ["money.", "spending.", "income.", "budget.", "savings."],
    []
  );
  const PAUSE_BLINK_MAX = 3;
  const TYPING_SPEED = 100;
  const CURSOR_BLINK_SPEED = 500;

  const [phraseIndex, setPhraseIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(PHRASES[phraseIndex].length);
  const [phase, setPhase] = useState(WritingPhase.PAUSING);
  const [pauseBlinkCounter, setPauseBlinkCounter] = useState(0);

  useEffect(() => {
    let intervalLength = TYPING_SPEED;
    if (phase === WritingPhase.PAUSING) {
      intervalLength = CURSOR_BLINK_SPEED;
    } else if (phase === WritingPhase.DELETING && charIndex <= 0) {
      intervalLength = 1;
    }

    const interval = setInterval(() => {
      if (phase === WritingPhase.DELETING && charIndex > 0) {
        setCharIndex((prevState) => prevState - 1);
      } else if (phase === WritingPhase.DELETING) {
        setPhase(WritingPhase.WRITING);
        setPhraseIndex((prevState) => (prevState + 1) % PHRASES.length);
      } else if (
        phase === WritingPhase.WRITING &&
        charIndex < PHRASES[phraseIndex].length
      ) {
        setCharIndex((prevState) => prevState + 1);
      } else if (
        phase === WritingPhase.WRITING &&
        charIndex >= PHRASES[phraseIndex].length
      ) {
        setPhase(WritingPhase.PAUSING);
      } else if (
        phase === WritingPhase.PAUSING &&
        pauseBlinkCounter < PAUSE_BLINK_MAX
      ) {
        setPauseBlinkCounter((prevState) => prevState + 1);
      } else if (
        phase === WritingPhase.PAUSING &&
        pauseBlinkCounter >= PAUSE_BLINK_MAX
      ) {
        setPhase(WritingPhase.DELETING);
        setPauseBlinkCounter(0);
      }
    }, intervalLength);

    return () => clearInterval(interval);
  }, [PHRASES, charIndex, pauseBlinkCounter, phase, phraseIndex]);

  return (
    <React.Fragment>
      <span className={styles.purpleColor}>
        {PHRASES[phraseIndex].substring(0, charIndex)}
      </span>
      <Cursor
        showCursor={
          phase !== WritingPhase.PAUSING || pauseBlinkCounter % 2 === 0
        }
      />
    </React.Fragment>
  );
}

export function LandingPage() {
  useEffect(() => window.scrollTo(0, 0), []);

  const navigate = useNavigate();

  return (
    <div className={styles.fullContainer}>
      <Header />
      <div className={styles.mainContainer}>
        <div className={styles.spacingContainer}>
          <div className={styles.bigText}>
            <div className={styles.bigHookText}>
              Understand your <br className={styles.mobileOnly} />
              <WrittenEnding />
            </div>
          </div>
          <div className={styles.smallText}>
            Straightforward personal finance.
          </div>
          <div className={styles.actionButtonContainer}>
            <button
              className={styles.actionButton}
              onClick={() => navigate("/auth/signup")}
            >
              Get started
            </button>
          </div>
          <div className={styles.spacer} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

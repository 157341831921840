import * as z from "zod";

export const firestoreDocBudgetSchema = z.object({
  name: z.string(),
  begin_date_timestamp_secs: z.number(),
  end_date_timestamp_secs: z.number(),
  // line items is a bit complicated because firestore doesnt support Map.
  // So we want an object where each key is a category id. The catchall
  // validates all field values for unrecognized keys against that type.
  // That is the best we can do because we cant statically type for keys
  // that are category ids.
  line_items: z.object({}).catchall(
    z.object({
      formula: z.string(),
    }))
});

export type FirestoreDocBudget = z.infer<typeof firestoreDocBudgetSchema>;

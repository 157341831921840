import { FirestoreDocCategory, FirestoreDocUser } from "breadcommon";
import { createContext, useEffect, useState } from "react";
import { firestoreCategoriesSubscribe } from "./firestoreIo";

export const CategoriesContext = createContext<
  Map<string, FirestoreDocCategory>
>(new Map());

export function CategoriesContextProvider(props: {
  children: JSX.Element;
  user: FirestoreDocUser;
  markInitialLoadComplete: () => void;
}): JSX.Element {
  const [Categories, setCategories] = useState<
    Map<string, FirestoreDocCategory>
  >(new Map());

  useEffect(() => {
    return firestoreCategoriesSubscribe(
      props.user.uid,
      setCategories,
      props.markInitialLoadComplete
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.uid]);

  return (
    <CategoriesContext.Provider value={Categories}>
      {props.children}
    </CategoriesContext.Provider>
  );
}

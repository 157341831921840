import { BreadTransaction } from "breadcommon";
import styles from "./InlineReviewed.module.scss";
import { firestoreUpdateTransaction } from "../firebaseio/firestoreIo";
import { useContext } from "react";
import { InlineTransactionContext } from "./InlineTransaction";
import { UserContext } from "../firebaseio/UserContext";
import { ReviewedIcon } from "../common/ReviewedIcon";

export function InlineReviewed(props: { transaction: BreadTransaction }) {
  const { transactionSelected } = useContext(InlineTransactionContext);
  const user = useContext(UserContext);

  function handleClick() {
    firestoreUpdateTransaction(
      user.uid,
      props.transaction.id,
      {
        manual_data: { reviewed: !props.transaction.reviewed },
      },
      null
    );
  }

  const icon = <ReviewedIcon reviewed={props.transaction.reviewed} />

  return (
    <div
      onClick={handleClick}
      className={
        transactionSelected
          ? `${styles.transactionSelected} ${styles.inlineReviewed}`
          : `${styles.inlineReviewed}`
      }
    >
      {icon}
    </div>
  );
}

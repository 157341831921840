import styles from "./InlineAccount.module.scss";
import { useContext } from "react";
import { HorizontalSpacer } from "../utils/Utils"
import { InstitutionLogo } from "./InstitutionLogo"
import { InstitutionsContext } from "../firebaseio/InstitutionsContext";
import { AccountsContext } from "../firebaseio/AccountsContext";

export function InlineAccount({
  account_id,
  logoGap = 15,
  logoSize = 18.5,
}: {
  account_id: string | null | undefined,
  logoGap?: number,
  logoSize?: number,
}): JSX.Element | null {
  const accounts = useContext(AccountsContext);
  const institutions = useContext(InstitutionsContext);

  if (account_id == null) {
    return null;
  }
  const account = accounts.get(account_id);
  if (account === undefined) {
    return null;
  }

  const institution = account.institution_id !== null ? institutions.get(account.institution_id) : undefined;
  return (
    <div className={styles.inlineContainer}>
      <InstitutionLogo
        institution={institution}
        size={logoSize}
      ></InstitutionLogo>
      <HorizontalSpacer width={logoGap} />
      <div className={styles.inlineTextbox}>{account.name}</div>
    </div>
  );
}
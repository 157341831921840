import { HorizontalSpacer, VerticalSpacer } from "../utils/Utils";
import settingsMenuStyles from "./SettingsScreen.module.scss";
import categorySettingsStyles from "./CategorySettings.module.scss";
import categoryEditModalStyles from "./CategoryEditModal.module.scss";
import buttonStyles from "../common/Buttons.module.scss";
import layoutStyles from "../utils/Layout.module.scss";
import { Icon } from "../Icon";
import { KeyboardEvent, useContext, useEffect, useRef, useState } from "react";
import EmojiPicker from "emoji-picker-react";
import Modal from "react-modal";
import "./EmojiPicker.scss";
import { useClickOutsideElement } from "../utils/customHooks";
import { FirestoreDocCategory } from "breadcommon";
import {
  firestoreCreateCategory,
  firestoreDeleteCategoryByIdAndUpdateTransactions,
  firestoreFinalUpdateCategoryOrderPosition,
  firestoreTempUpdateCategoryOrderPosition,
  firestoreUpdateCategory,
} from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { keyCodes } from "../utils/KeyCodes";
import dayjs from "dayjs";
import Draggable from "react-draggable";

function EditCategoryModal(props: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  initialCategory: FirestoreDocCategory | null;
  saveAction: (name: string, emoji: string) => void;
  // category: Category;
}): JSX.Element {
  const [selectedName, setSelectedName] = useState<string>(
    props.initialCategory?.name === undefined ? "" : props.initialCategory.name
  );
  const [selectedEmoji, setSelectedEmoji] = useState<string>(
    props.initialCategory?.emoji === undefined
      ? "💰"
      : props.initialCategory.emoji
  );
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emojiAndPickerRef = useRef<HTMLDivElement>(null);

  useClickOutsideElement(
    emojiAndPickerRef,
    () => setShowEmojiPicker(false),
    showEmojiPicker,
    window
  );

  function saveAndClose() {
    props.saveAction(selectedName, selectedEmoji);
    props.setIsOpen(false);
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.code === keyCodes.ENTER && !e.repeat && selectedName !== "") {
      saveAndClose();
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      className={categoryEditModalStyles.modal}
      overlayClassName={categoryEditModalStyles.modalOverlay}
      ariaHideApp={false}
    >
      <div className={categoryEditModalStyles.contentContainer}>
        <div className={categoryEditModalStyles.headerContainer}>
          <div className={categoryEditModalStyles.title}>{props.title}</div>
          <Icon
            name="close"
            size={23}
            className={categoryEditModalStyles.cancelButton}
            onClick={() => props.setIsOpen(false)}
          ></Icon>
        </div>
        <div
          className={
            categoryEditModalStyles.editCategoryModalEditingRowContainer
          }
        >
          <div ref={emojiAndPickerRef}>
            <div
              className={`${categoryEditModalStyles.emojiContainer} ${
                showEmojiPicker
                  ? categoryEditModalStyles.emojiContainerHighlighted
                  : ""
              }`}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            >
              {selectedEmoji !== "" ? (
                selectedEmoji
              ) : (
                <Icon
                  className={categoryEditModalStyles.emojiPlaceholder}
                  name="add_reaction"
                  size={20}
                ></Icon>
              )}
            </div>
            {showEmojiPicker ? (
              <div className={categoryEditModalStyles.emojiPickerContainer}>
                <EmojiPicker
                  onEmojiClick={(emoji) => {
                    setSelectedEmoji(emoji.emoji);
                    setShowEmojiPicker(false);
                  }}
                  height={350}
                  width={350}
                  previewConfig={{ showPreview: false }}
                ></EmojiPicker>
              </div>
            ) : null}
          </div>
          <input
            ref={nameInputRef}
            className={categoryEditModalStyles.categoryNameInput}
            placeholder="Category Name"
            onChange={() =>
              setSelectedName(
                nameInputRef.current !== null ? nameInputRef.current.value : ""
              )
            }
            onKeyDown={(e) => handleKeyDown(e)}
            value={selectedName}
            autoFocus={true}
          ></input>
        </div>
        <div
          className={
            categoryEditModalStyles.editCategoryModalBottomRowContainer
          }
        >
          <button
            className={buttonStyles.saveButton}
            disabled={selectedName === ""}
            onClick={() => {
              saveAndClose();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
}

function CategoryDefinition(props: {
  category: FirestoreDocCategory;
  setSomethingIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  draggingIndexRef: React.MutableRefObject<number | null>;
  dragTargetIndexRef: React.MutableRefObject<number | null>;
  transitionSpacersRef: React.MutableRefObject<boolean | null>;
  spacerRefs: React.MutableRefObject<Map<number, HTMLDivElement>>;
  tempUpdateCategoryOrder: (categoryId: string) => void;
  finalUpdateCategoryOrder: () => void;
}): JSX.Element {
  const [editCategoryModalIsOpen, setEditCategoryModalIsOpen] =
    useState<boolean>(false);
  const [dragging, setDragging] = useState(false);
  const [dragResetNeeded, setDragResetNeeded] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const user = useContext(UserContext);
  const categories = useContext(CategoriesContext);
  const defRef = useRef<HTMLDivElement>(null);

  // TODO: we will need some corrective functionality on reading transactions that if
  // a category has been deleted, we remove the category from the transaction before returning it.
  // Or we can run backfill jobs.
  async function deleteCategory(): Promise<void> {
    firestoreDeleteCategoryByIdAndUpdateTransactions(
      user.uid,
      props.category,
      Array.from(categories.values())
    );
  }

  async function updateCategoryName(
    name: string,
    emoji: string
  ): Promise<void> {
    firestoreUpdateCategory(user.uid, props.category.id, {
      name: name,
      emoji: emoji,
    });
  }

  function handleDragStart() {
    const scrollTop = defRef.current?.scrollTop ?? 0;
    let top = defRef.current?.offsetTop ?? 0;
    setTopOffset(top - scrollTop);
    setDragging(true);
    props.setSomethingIsDragging(true);
    props.transitionSpacersRef.current = false;
    props.draggingIndexRef.current = props.category.order_position;
    props.dragTargetIndexRef.current = props.category.order_position;
  }

  function handleDragStop() {
    setDragging(false);
    setDragResetNeeded(true);
    setTimeout(() => setDragResetNeeded(false), 50);
    props.finalUpdateCategoryOrder();
    props.draggingIndexRef.current = null;
    props.dragTargetIndexRef.current = null;
    props.transitionSpacersRef.current = false;
    props.setSomethingIsDragging(false);
  }

  function overlaps(a: DOMRect, b: DOMRect): boolean {
    return a.top > 0 && b.top > 0 && a.top < b.bottom && b.top < a.bottom;
  }

  function handleDrag() {
    for (let [spacerIndex, spacer] of Array.from(
      props.spacerRefs.current.entries()
    )) {
      if (
        defRef.current &&
        overlaps(
          defRef.current?.getBoundingClientRect(),
          spacer.getBoundingClientRect()
        ) &&
        props.dragTargetIndexRef.current !== spacerIndex
      ) {
        props.dragTargetIndexRef.current = spacerIndex;
        props.transitionSpacersRef.current = true;
        props.tempUpdateCategoryOrder(props.category.id);
      }
    }
  }

  let width: string = "100%";
  if (dragging && defRef.current?.parentElement) {
    width = `${defRef.current?.parentElement?.getBoundingClientRect().width}px`;
  }

  const categoryDef = (
    <div
      ref={defRef}
      className={`${categorySettingsStyles.categoryDefinitionContainer} ${
        dragging ? categorySettingsStyles.dragging : ""
      }`}
      style={{ top: `${topOffset}px`, width: `${width}` }}
    >
      {editCategoryModalIsOpen ? (
        <EditCategoryModal
          isOpen={editCategoryModalIsOpen}
          setIsOpen={setEditCategoryModalIsOpen}
          title="Edit Category"
          initialCategory={props.category}
          saveAction={updateCategoryName}
        ></EditCategoryModal>
      ) : null}
      <Icon
        name={"drag_indicator"}
        size={20}
        className={categorySettingsStyles.dragIcon}
      />
      <HorizontalSpacer width={10}></HorizontalSpacer>
      <div className={categorySettingsStyles.categoryNameContainer}>
        {props.category.emoji !== undefined && props.category.emoji !== ""
          ? `${props.category.emoji}    ${props.category.name}`
          : `        ${props.category.name}`}
      </div>
      <div className={categorySettingsStyles.categoryRightContainer}>
        <Icon
          name="edit"
          size={20}
          className={categorySettingsStyles.nameEditIcon}
          onClick={() => setEditCategoryModalIsOpen(true)}
        ></Icon>
        <HorizontalSpacer width={20}></HorizontalSpacer>
        <Icon
          name="delete"
          size={20}
          onClick={deleteCategory}
          className={categorySettingsStyles.deleteButton}
        ></Icon>
      </div>
    </div>
  );

  if (dragResetNeeded) {
    return categoryDef;
  }

  return (
    <Draggable
      handle={`.${categorySettingsStyles.dragIcon}`}
      axis="y"
      onStart={handleDragStart}
      onStop={handleDragStop}
      onDrag={handleDrag}
    >
      {categoryDef}
    </Draggable>
  );
}

function Spacer(props: {
  index: number;
  dragTargetIndex: number | null;
  spacerRefs: React.MutableRefObject<Map<number, HTMLDivElement>>;
  transitionSpacers: boolean;
}): JSX.Element {
  const spacerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (spacerRef.current) {
      props.spacerRefs.current.set(props.index, spacerRef.current);
    }
  }, [props.index, props.spacerRefs]);

  function getClasses() {
    let className = categorySettingsStyles.spacer;
    if (props.index === props.dragTargetIndex) {
      className = `${className} ${categorySettingsStyles.spacerExpanded}`;
    }
    if (!props.transitionSpacers) {
      className = `${className} ${categorySettingsStyles.spacerNoTransition}`;
    }
    return className;
  }

  return <div ref={spacerRef} className={getClasses()}></div>;
}

function CategoriesList(): JSX.Element {
  const [somethingIsDragging, setSomethingIsDragging] =
    useState<boolean>(false);
  const draggingIndexRef = useRef<number | null>(null);
  const dragTargetIndexRef = useRef<number | null>(null);
  const transitionSpacersRef = useRef<boolean>(false);
  const user = useContext(UserContext);
  const categories = useContext(CategoriesContext);
  const spacerRefs = useRef<Map<number, HTMLDivElement>>(new Map());

  const sortedCategories = Array.from(categories.values()).sort(
    (a, b) => a.order_position - b.order_position
  );

  function calcTempCategoryIndex() {
    if (
      draggingIndexRef.current === null ||
      dragTargetIndexRef.current === null
    ) {
      return null;
    }
    let diff = draggingIndexRef.current - dragTargetIndexRef.current;
    if (diff > 0) {
      return draggingIndexRef.current - diff - 0.5;
    }
    if (diff < 0) {
      return (diff = draggingIndexRef.current - diff + 0.5);
    }
    return dragTargetIndexRef.current;
  }

  function tempUpdateCategoryOrder(categoryId: string) {
    const tempCategoryIndex = calcTempCategoryIndex();
    if (tempCategoryIndex !== null) {
      firestoreTempUpdateCategoryOrderPosition(
        user.uid,
        categoryId,
        tempCategoryIndex
      );
    }
  }

  function finalUpdateCategoryOrder() {
    const tempCategoryIndex = calcTempCategoryIndex();
    if (
      draggingIndexRef.current !== null &&
      dragTargetIndexRef.current !== null &&
      tempCategoryIndex !== null
    ) {
      firestoreFinalUpdateCategoryOrderPosition(
        user.uid,
        sortedCategories,
        draggingIndexRef.current,
        tempCategoryIndex,
        dragTargetIndexRef.current
      );
    }
  }

  function arrayMove<T>(arr: T[], fromIndex: number, toIndex: number): T[] {
    let newArr = [...arr];
    var element = newArr[fromIndex];
    newArr.splice(fromIndex, 1);
    newArr.splice(toIndex, 0, element);
    return newArr;
  }

  let adjustedSortedCategories = sortedCategories;
  if (
    draggingIndexRef.current !== null &&
    dragTargetIndexRef.current !== null
  ) {
    adjustedSortedCategories = arrayMove(
      sortedCategories,
      dragTargetIndexRef.current,
      draggingIndexRef.current
    );
  }

  let list: JSX.Element[] = [];
  let categoryIndex = 0;
  let spacerIndex = 0;
  for (const category of adjustedSortedCategories) {
    if (
      !somethingIsDragging ||
      draggingIndexRef.current === null ||
      categoryIndex !== draggingIndexRef.current + 1
    ) {
      list.push(
        <Spacer
          index={spacerIndex}
          dragTargetIndex={dragTargetIndexRef.current}
          spacerRefs={spacerRefs}
          transitionSpacers={transitionSpacersRef.current}
        ></Spacer>
      );
      spacerIndex++;
    }
    list.push(
      <CategoryDefinition
        key={category.id}
        category={category}
        setSomethingIsDragging={setSomethingIsDragging}
        draggingIndexRef={draggingIndexRef}
        dragTargetIndexRef={dragTargetIndexRef}
        transitionSpacersRef={transitionSpacersRef}
        spacerRefs={spacerRefs}
        tempUpdateCategoryOrder={tempUpdateCategoryOrder}
        finalUpdateCategoryOrder={finalUpdateCategoryOrder}
      ></CategoryDefinition>
    );
    categoryIndex++;
  }
  if (draggingIndexRef.current !== adjustedSortedCategories.length - 1)
    list.push(
      <Spacer
        index={spacerIndex}
        dragTargetIndex={dragTargetIndexRef.current}
        spacerRefs={spacerRefs}
        transitionSpacers={transitionSpacersRef.current}
      ></Spacer>
    );

  return <div className={categorySettingsStyles.listContainer}>{list}</div>;
}

export function CategorySettings(): JSX.Element {
  const [createCategoryModalIsOpen, setCreateCategoryModalIsOpen] =
    useState<boolean>(false);
  const categories = useContext(CategoriesContext);
  const user = useContext(UserContext);
  const createCategoryButtonRef = useRef<HTMLButtonElement>(null);

  // After someone clicks the Create Category button, it opens the
  // category editing modal. Then, after they close the category editing
  // modal, the focus returns to the Create Category button. If someone
  // saves & closes the category editing modal by pressing the `enter` key,
  // then they will end up
  useEffect(() => createCategoryButtonRef.current?.blur());

  async function createCategory(name: string, emoji: string): Promise<void> {
    firestoreCreateCategory(user.uid, {
      name: name,
      emoji: emoji,
      created_timestamp_secs: dayjs().unix(),
      order_position: categories.size,
    });
  }

  return (
    <div className={categorySettingsStyles.pageContainer}>
      <button
        className={settingsMenuStyles.basicButton}
        onClick={() => setCreateCategoryModalIsOpen(true)}
        ref={createCategoryButtonRef}
      >
        <div className={layoutStyles.flexH}>
          <Icon name={"add"} size={22}></Icon>
          <HorizontalSpacer width={8}></HorizontalSpacer> Create category
        </div>
      </button>
      {createCategoryModalIsOpen ? (
        <EditCategoryModal
          isOpen={createCategoryModalIsOpen}
          setIsOpen={setCreateCategoryModalIsOpen}
          title="Create Category"
          initialCategory={null}
          saveAction={createCategory}
        ></EditCategoryModal>
      ) : null}
      <VerticalSpacer height={20} />
      <CategoriesList />
      <div />
    </div>
  );
}

import dayjs, { Dayjs } from "dayjs";
import { FirestoreDocTransaction, InvestmentTransactionType } from "./FirestoreDocTransaction";

export type Merchant = {
  name: string;
  logo: string | null;
};

function getMerchant(transaction: FirestoreDocTransaction): Merchant | null {
  let merchantName = transaction.plaid_data?.merchant_name;

  if (!merchantName) {
    return null;
  }

  return {
    name: merchantName,
    logo: transaction.plaid_data?.logo_url ?? null,
  };
}

/**
 * The computed type of a transaction, which is constructed from the raw
 * persisted data about an transaction which can contain information synced from
 * Plaid, data specified/overwritten by the user, etc.
 */
export type BreadTransaction = {
  id: string;
  date: Dayjs;
  account_id: string;
  amount: number;
  merchant: Merchant | null;
  description: string | null;
  categoryId: string | null;
  reviewed: boolean;
  type: string | null,
  subtype: string | null,
};

/**
 * Parse the raw transaction data from the DB into a computed BreadTransaction.
 */
export const parseBreadTransaction = (
  transaction: FirestoreDocTransaction
): BreadTransaction | null => {
  let id = transaction.plaid_data?.transaction_id ?? transaction.plaid_investment_data?.investment_transaction_id;
  let date = transaction.plaid_data?.authorized_date ?? transaction.plaid_data?.date ?? transaction.plaid_investment_data?.date;
  let accountId = transaction.plaid_data?.account_id ?? transaction.plaid_investment_data?.account_id;
  let amount = transaction.plaid_data?.amount ?? transaction.plaid_investment_data?.amount;
  if (amount) amount = -amount;

  if (!id || !date || !accountId || !amount) {
    return null;
  }

  const parsedDate = dayjs(date, "YYYY-MM-DD", true);
  if (!parsedDate.isValid()) {
    throw Error(
      `Unrecognized date format returned by Plaid for transaction ${id}: "${date}"`
    );
  }

  let description = transaction.plaid_data?.name ?? transaction.plaid_investment_data?.name ?? null;

  return {
    id: id,
    date: parsedDate,
    account_id: accountId,
    merchant: getMerchant(transaction),
    description: description,
    categoryId: transaction.manual_data.category_id,
    amount: amount,
    reviewed: transaction.manual_data.reviewed,
    type: transaction.plaid_investment_data?.type ?? null,
    subtype: transaction.plaid_investment_data?.subtype ?? null,
  };
};
import { Merchant } from "breadcommon";
import styles from "./MerchantLogo.module.scss";

export function MerchantLogo(props: {
  merchant: Merchant | null | undefined;
  size: number;
}): JSX.Element {
  const sizePx = `${props.size}px`;
  if (props.merchant && props.merchant.logo !== null) {
    return (
      <img
        src={props.merchant.logo}
        alt="merchant_logo"
        className={styles.merchantLogo}
        style={{
          width: sizePx,
          height: sizePx,
        }}
      ></img>
    );
  }

  if (props.merchant) {
    return (
      <div
        className={`${styles.merchantLogo} ${styles.merchantLogoPlaceholder}`}
        style={{
          width: sizePx,
          height: sizePx,
          fontSize: props.size / 1.5,
        }}
      >
        {props.merchant.name.charAt(0)}
      </div>
    );
  }

  return <div />;
}

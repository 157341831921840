import styles from "./DollarAmount.module.scss";

function PlusMinus(props: {
  n_str: string;
  unrounded_num: number;
  showPlus: boolean;
  showMinus: boolean;
}) {
  // we can't just round() the number because Intl.NumberFormat
  // doesn't follow the same rules as round() so we won't always end up with
  // 0 under the exact same circumstances.
  if (parseInt(props.n_str) === 0) return null;
  if (props.unrounded_num > 0 && props.showPlus)
    return <span className={styles.sign}>+</span>;
  if (props.unrounded_num < 0 && props.showMinus)
    return <span className={styles.sign}>-</span>;
  return null;
}

export function DollarAmount(props: {
  n: number;
  // use 2 decimal places instead of 0
  includeDecimals: boolean;
  showPlus: boolean;
  showMinus: boolean;
  showDollarSign: boolean;
  monospaceFont: boolean;
  align: "left" | "center" | "right";
}): JSX.Element {
  const { n, includeDecimals } = props;

  let numDecimals = 2;
  if (!includeDecimals) {
    numDecimals = 0;
  }

  let n_str = new Intl.NumberFormat("en-US", {
    signDisplay: "never",
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  }).format(n);
  if (n === 0) {
    n_str = "--";
  }

  return (
    <div
      className={`${styles.amountContainer} ${
        props.monospaceFont ? styles.monospaceFont : ""
      } ${props.align === "left" ? styles.alignLeft : ""} ${
        props.align === "center" ? styles.alignCenter : ""
      }`}
    >
      <PlusMinus
        n_str={n_str}
        unrounded_num={n}
        showPlus={props.showPlus}
        showMinus={props.showMinus}
      />
      <span>{`${props.showDollarSign ? "$" : ""}${n_str}`}</span>
    </div>
  );
}

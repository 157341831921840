import { useRef, useState } from "react";
import styles from "./DropDown.module.scss";
import { Icon } from "../Icon";
import { HorizontalSpacer } from "../utils/Utils";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useClickOutsideElement } from "../utils/customHooks";
import { DARK_TEXT_COLOR } from "./colors";

function DropDownSelection(props: {
  selectedEntryName: string;
  width: number;
  color: string;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div
      className={`${styles.dropDownSelection} ${props.isExpanded ? styles.expanded : ""
        }`}
      style={{ width: `${props.width}px`, color: `${props.color}` }}
      onClick={() => props.setIsExpanded(!props.isExpanded)}
    >
      <div className={styles.selectionOverflowContainer}>
        {props.selectedEntryName}
      </div>
      <HorizontalSpacer width={10} />
      <Icon
        name={props.isExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        size={20}
      />
    </div>
  );
}

function ExpandedDropDown(props: {
  selectedEntryName: string;
  width: number;
  color: string;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  entries: Map<string, string>;
  setSelectedEntryId: React.Dispatch<React.SetStateAction<string>>;
  onChange: (id: string) => void;
}) {
  const expandedContainerRef = useRef<HTMLDivElement>(null);

  useClickOutsideElement(
    expandedContainerRef,
    () => props.setIsExpanded(false),
    true,
    document
  );

  function handleEntryClick(id: string) {
    props.setSelectedEntryId(id);
    props.setIsExpanded(false);
    props.onChange(id);
  }

  const menu: JSX.Element[] = [];
  props.entries.forEach((name, id) =>
    menu.push(
      <button
        className={styles.expandedItem}
        onClick={() => handleEntryClick(id)}
      >
        {name}
      </button>
    )
  );

  return (
    <div
      className={styles.expandedContainer}
      style={{ width: `${props.width}px`, color: props.color }}
      ref={expandedContainerRef}
    >
      <DropDownSelection
        selectedEntryName={props.selectedEntryName}
        width={props.width}
        color={props.color}
        isExpanded={true}
        setIsExpanded={props.setIsExpanded}
      />

      {/* For unknown reasons, the custom scrollbar is being super
      fickle in this specific instance. It seems to be caused by the fact that
      the custom scrollbar content was not taking on the right overflow
      properies. This is the only configuration I tried where I could get the
      custom scrollbar to show up properly, so here we are. */}
      <div className={styles.expandedMenu}>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: "never" },
            overflow: { x: "hidden" },
          }}
          className={styles.scrollbar}
        >
          {menu}
        </OverlayScrollbarsComponent>
      </div>
    </div>
  );
}

function CollapsedDropDown(props: {
  selectedEntryName: string;
  width: number;
  color: string;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <DropDownSelection
      selectedEntryName={props.selectedEntryName}
      width={props.width}
      color={props.color}
      isExpanded={false}
      setIsExpanded={props.setIsExpanded}
    />
  );
}

// NOTE: According to the docs, "A Map object iterates entries, keys, and values
// in the order of entry insertion."
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map#objects_vs._maps
export function DropDown(props: {
  entries: Map<string, string>;
  initEntryId: string;
  onChange: (id: string) => void;
  width?: number;
  color?: string;
}) {
  const width = props.width ?? 250;
  const color = props.color ?? DARK_TEXT_COLOR;

  const [selectedEntryId, setSelectedEntryId] = useState<string>(
    props.initEntryId
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (isExpanded) {
    return (
      <ExpandedDropDown
        selectedEntryName={props.entries.get(selectedEntryId) ?? ""}
        width={width}
        color={color}
        setIsExpanded={setIsExpanded}
        entries={props.entries}
        setSelectedEntryId={setSelectedEntryId}
        onChange={props.onChange}
      ></ExpandedDropDown>
    );
  }

  return (
    <CollapsedDropDown
      selectedEntryName={props.entries.get(selectedEntryId) ?? ""}
      width={width}
      color={color}
      setIsExpanded={setIsExpanded}
    ></CollapsedDropDown>
  );
}

import { BreadTransaction } from "./BreadTransaction";
import { FirestoreDocRule } from "./FirestoreDocRule";

function matchesRuleCriterion(
  ruleCriterionValue: string | null,
  transactionFieldValue: string | null
) {
  if (transactionFieldValue === null) {
    transactionFieldValue = getNullFieldValueCriterionEquivalent();
  }
  if (
    ruleCriterionValue === null ||
    ruleCriterionValue === transactionFieldValue
  ) {
    return true;
  }
  return false;
}

function substringMatchesRuleCriterion(
  ruleCriterionValue: string | null,
  transactionFieldValue: string | null
) {
  if (transactionFieldValue === null) {
    transactionFieldValue = getNullFieldValueCriterionEquivalent();
  }
  if (
    ruleCriterionValue === null ||
    transactionFieldValue.includes(ruleCriterionValue)
  ) {
    return true;
  }
  return false;
}

// We use null in the rule criteria to indicate that a field is not active in
// the rule (i.e that field should be ignored for matching purposes).
// However, some of our field values can be null (i.e. merchant can be null).
// In the case of a null field value, we convert it to a placeholder value (in the case of strings this is the empty string).
export function getNullFieldValueCriterionEquivalent() {
  return "";
}

/**
 * Best INACTIVE Match: this is the rule with the minimum order position that matches the transaction, but where the category does NOT match the one already set on the transaction
 * Best ACTIVE Match: this is the rule with the minimum order position that matches the transaction AND the category already set on that transcation
 * 
 * "Best Match":
 *   - for reviewed transcations, return the best active match if one can be found, otherwise fall back to the best inactive match
 *   - for unreviewed transactions, take the best match regardless of whether it is active or not
 * 
 * If no match is found, this function returns {
 *     bestMatchRule: null, 
 *     bestMatchRuleIsActive: false
 *   }
 */
export function matchTransactionToRule(
  transaction: BreadTransaction,
  rules: Map<string, FirestoreDocRule>
): {
  bestMatchRule: FirestoreDocRule | null,
  bestMatchRuleIsActive: boolean // false when bestMatchRule is null or bestMatchRule's category is not the transaction's category
} {
  let bestMatchRule: FirestoreDocRule | null = null;
  let bestMatchRuleIsActive = false;
  for (const [_ruleId, thisRule] of rules) {
    if (transactionMatchesSpecificRule(transaction, thisRule)) {
      const thisRuleIsActive = thisRule.action.category_id === transaction.categoryId;
      if (bestMatchRule === null) {
        bestMatchRule = thisRule;
        bestMatchRuleIsActive = thisRuleIsActive;
      } else if (!bestMatchRuleIsActive && thisRuleIsActive && transaction.reviewed) {
        bestMatchRule = thisRule;
        bestMatchRuleIsActive = thisRuleIsActive;
      } else if (bestMatchRuleIsActive && !thisRuleIsActive && transaction.reviewed) {
        continue;
      } else if (thisRule.order_position < bestMatchRule.order_position) {
        // either thisRuleIsActive must equal bestMatchRuleIsActive here OR the transaction is unreviewed
        bestMatchRule = thisRule;
        bestMatchRuleIsActive = thisRuleIsActive;
      } else {
        continue;
      }
    }
  }
  return { bestMatchRule, bestMatchRuleIsActive };
}

/**
 * This checks whether a specific rule matches a transaction.
 * You usually want to use the `matchTransactionToRule` function instead.
 * You should ABSOLUTELY NOT loop over this function to find a matching rule.
 */
export function transactionMatchesSpecificRule(
  transaction: BreadTransaction,
  rule: FirestoreDocRule
): boolean {
  return matchesRuleCriterion(rule.criteria.account_id, transaction.account_id) &&
    matchesRuleCriterion(
      rule.criteria.merchant_name,
      transaction.merchant?.name ?? null
    ) &&
    matchesRuleCriterion(rule.criteria.description, transaction.description) &&
    substringMatchesRuleCriterion(rule.criteria.partial_description ?? null, transaction.description) &&
    matchesRuleCriterion(rule.criteria.type ?? null, transaction.type) &&
    matchesRuleCriterion(rule.criteria.subtype ?? null, transaction.subtype);
}

import { FirestoreDocInstitution } from "./FirestoreDocInstitution";

/**
 * The computed type of a institution, which is constructed from the raw
 * persisted data about an institution which can contain information synced from
 * Plaid, data specified/overwritten by the user, etc.
 */
export type BreadInstitution = {
  id: string;
  name: string;
  primary_color: string | null;
  logo: string | null;
};

/**
 * Parse the raw institution data from the DB into a computed BreadTransaction.
 */
export const parseBreadInstitution = (
  institution: FirestoreDocInstitution
): BreadInstitution | null => {
  let id = institution.plaid_data?.institution_id;
  let name = institution.plaid_data?.name;

  if (!id || !name) {
    return null;
  }

  return {
    id: id,
    name: name,
    primary_color: institution.plaid_data?.primary_color ?? null,
    logo: institution.plaid_data?.logo ?? null,
  };
};

import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { AuthScreen } from "./auth/AuthScreen";
import { useContext } from "react";
import { AuthContext } from "./firebaseio/AuthContext";
import { DefaultRoute } from "./DefaultRoute";
import { PrivacyPolicyPage } from "./website/PrivacyPolicyPage";

export function Router() {
  const auth = useContext(AuthContext);

  const router = createBrowserRouter([
    {
      path: "/privacy",
      element: <PrivacyPolicyPage />,
    },
    {
      path: "/auth/*",
      element: <AuthScreen></AuthScreen>,
      loader: () => {
        if (auth) {
          return redirect("/");
        }
        return null;
      },
    },
    {
      path: "/*",
      element: <DefaultRoute />,
    },
  ]);
  return <RouterProvider router={router}></RouterProvider>;
}

import { BreadInstitution } from "breadcommon";
import { Icon } from "../Icon";
import styles from "./InstitutionLogo.module.scss";

export function InstitutionLogo(props: {
  institution: BreadInstitution | null | undefined;
  size: number;
}): JSX.Element {
  const sizePx = `${props.size}px`;
  if (props.institution && props.institution.logo !== null) {
    return (
      <img
        src={`data:image/png;base64, ${props.institution?.logo}`}
        alt="institution_logo"
        className={styles.institutionLogo}
        style={{
          width: sizePx,
          height: sizePx,
        }}
      ></img>
    );
  }

  return (
    <div
      className={`${styles.institutionLogo} ${styles.institutionLogoPlaceholder}`}
      style={{
        width: sizePx,
        height: sizePx,
        color:
          props.institution && props.institution.primary_color !== null
            ? props.institution.primary_color
            : "#6b6f75",
      }}
    >
      <Icon name={"account_balance"} size={props.size / 2} fill={true}></Icon>
    </div>
  );
}

import { number } from "zod";
import { AccountStatuses } from "./AccountStatuses";
import { FirestoreDocAccount } from "./FirestoreDocAccount";
import dayjs, { Dayjs } from "dayjs";

/**
 * The computed type of an account, which is constructed from the raw
 * persisted data about an account which can contain information synced from
 * Plaid, data specified/overwritten by the user, etc.
 */
export type BreadAccount = {
  id: string;
  name: string;
  status: AccountStatuses;
  type: string | null;
  mask: string | null;
  current_balance: number | null;
  institution_id: string | null;
  plaid_item_id: string | null;
  sync_start_date: Dayjs;
  order_position: number;
};

/**
 * Parse the raw account data from the DB into a computed BreadTransaction.
 */
export const parseBreadAccount = (
  account: FirestoreDocAccount
): BreadAccount | null => {
  let id = account.plaid_data?.account_id;
  let name: string | null | undefined = account.manual_data?.name;
  if (!name) {
    name = account.plaid_data?.name;
  }

  if (!id || !name) {
    return null;
  }

  let balance = account.plaid_data?.balances.current ?? null;
  if (balance && account.plaid_data?.type === "credit") {
    balance = -balance;
  }

  return {
    id: id,
    name: name,
    status: account.system_data.status,
    type: account.plaid_data?.subtype ?? null,
    mask: account.plaid_data?.mask ?? null,
    current_balance: balance,
    institution_id: account.system_data.institution_id,
    plaid_item_id: account.system_data.plaid_item_id,
    sync_start_date: dayjs.unix(account.manual_data.sync_start_timestamp_secs),
    order_position: account.manual_data.order_position,
  };
};

import accountSettingsModalStyles from "./AccountSettingsModal.module.scss";
import buttonStyles from "../../common/Buttons.module.scss";
import Modal from "react-modal";
import { Icon } from "../../Icon";
import { HorizontalSpacer, VerticalSpacer } from "../../utils/Utils";
import { BreadAccount } from "breadcommon";
import {
  firestoreDeleteAccountById,
  firestoreUpdateAccount,
} from "../../firebaseio/firestoreIo";
import { useContext, useState } from "react";
import { UserContext } from "../../firebaseio/UserContext";
import { removeUnusedItemsAndInstitutions } from "../../firebaseio/firebaseFunctions";
import { AccountsContext } from "../../firebaseio/AccountsContext";
import { lineWobble } from "ldrs";

lineWobble.register();

export function AccountSettingsModal(props: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialAccount: BreadAccount;
}): JSX.Element {
  const [newAccountName, setNewAccountName] = useState(
    props.initialAccount.name
  );
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const user = useContext(UserContext);
  const accounts = useContext(AccountsContext);

  const contentVerticalSpacer = <VerticalSpacer height={30}></VerticalSpacer>;

  if (deleteInProgress) {
    return (
      <Modal
        isOpen={props.isOpen}
        className={accountSettingsModalStyles.modal}
        overlayClassName={accountSettingsModalStyles.modalOverlay}
        ariaHideApp={false}
      >
        <div className={accountSettingsModalStyles.modalContainer}>
          <div className={accountSettingsModalStyles.thinkingContainer}>
            <VerticalSpacer height={60}></VerticalSpacer>
            <l-line-wobble size="150" speed="2" color="#7d57ff"></l-line-wobble>
            <VerticalSpacer height={60}></VerticalSpacer>
            <div>Deleting account</div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={props.isOpen}
      className={accountSettingsModalStyles.modal}
      overlayClassName={accountSettingsModalStyles.modalOverlay}
      ariaHideApp={false}
    >
      <div className={accountSettingsModalStyles.modalContainer}>
        <div className={accountSettingsModalStyles.headerContainer}>
          <div className={accountSettingsModalStyles.title}>
            Account Settings
          </div>
          <Icon
            name="close"
            size={23}
            className={accountSettingsModalStyles.cancelButton}
            onClick={() => props.setIsOpen(false)}
          ></Icon>
        </div>
        <div className={accountSettingsModalStyles.contentContainer}>
          <div>
            <div className={accountSettingsModalStyles.headerText}>
              Account Name
            </div>
            <VerticalSpacer height={10}></VerticalSpacer>
            <input
              className={accountSettingsModalStyles.input}
              value={newAccountName}
              onChange={(e) => setNewAccountName(e.currentTarget.value)}
            ></input>
          </div>
          {contentVerticalSpacer}
          <div className={accountSettingsModalStyles.headerText}>
            Delete Account
          </div>
          <VerticalSpacer height={10}></VerticalSpacer>
          <div className={accountSettingsModalStyles.deleteAccountSection}>
            <div className={accountSettingsModalStyles.bodyText}>
              Unlink account and delete all related data (including
              transactions).
            </div>
            <HorizontalSpacer width={100}></HorizontalSpacer>
            <button
              className={buttonStyles.dangerousButton}
              onClick={async () => {
                setDeleteInProgress(true);

                // remove account from firebase
                try {
                  await firestoreDeleteAccountById(
                    user.uid,
                    props.initialAccount,
                    Array.from(accounts.values()),
                    true
                  );
                } catch (error) {
                  console.log(error);
                  setDeleteInProgress(false);
                  return;
                }

                // remove unused items & institutions from plaid & firebase
                await removeUnusedItemsAndInstitutions();
                props.setIsOpen(false);
              }}
            >
              Delete
            </button>
          </div>
        </div>
        <div className={accountSettingsModalStyles.bottomRowContainer}>
          {/* <HorizontalSpacer width={20}></HorizontalSpacer> */}
          {/* TODO: Add ability to close the account */}
          <button
            className={buttonStyles.saveButton}
            onClick={() => {
              firestoreUpdateAccount(user.uid, props.initialAccount.id, {
                manual_data: {
                  name: newAccountName,
                },
              });
              props.setIsOpen(false);
            }}
            disabled={newAccountName.length === 0}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
}

import { useNavigate, useParams } from "react-router-dom";
import { LoggedIn } from "./LoggedIn";
import { useContext, useEffect } from "react";
import { AuthContext } from "./firebaseio/AuthContext";
import { LandingPage } from "./website/LandingPage";

export function DefaultRoute() {
  const auth = useContext(AuthContext);

  const navigate = useNavigate();
  const { "*": route } = useParams();

  useEffect(() => {
    if (!auth && route !== "") {
      navigate("/");
    }
  }, [auth, navigate, route]);

  if (!auth) {
    return <LandingPage />;
  }

  return <LoggedIn />;
}

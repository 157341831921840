import ribbonStyles from "./Ribbon.module.scss";
import layoutStyles from "./utils/Layout.module.scss";
import { Icon } from "./Icon";
import { HorizontalSpacer } from "./utils/Utils";
import { TransactionsHeader } from "./transactions_screen/TransactionsHeader";
import { SyncTransactionsRibbonButton } from "./transactions_screen/SyncTransactions";
import { useContext } from "react";
import { MainNavScreens } from "breadcommon";
import { UrlContext } from "./UrlContext";
import { useNavigate } from "react-router-dom";
// import { PreferencesContext } from "./firebaseio/PreferencesContext";
import breadLogo from "./images/bread_logo.png";

function BreadLogo() {
  const navigate = useNavigate();

  return (
    <div
      className={ribbonStyles.breadLogoContainer}
      onClick={() => navigate("/")}
    >
      <img
        src={breadLogo}
        className={ribbonStyles.breadLogo}
        alt="Bread logo"
      ></img>
      <HorizontalSpacer width={4}></HorizontalSpacer>
      <span className={ribbonStyles.breadLogoName}>Bread</span>
    </div>
  );
}

function getSelectedClassIfAppropriate(
  mainNav: MainNavScreens,
  target: MainNavScreens
): string {
  return mainNav === target ? ribbonStyles.mainNavSelected : "";
}

function getRibbonClasses(
  floating: boolean,
  _mainNavScreen: MainNavScreens // in case we want to render each differently
): string {
  let classes = ribbonStyles.ribbon;
  if (floating) {
    return `${classes} ${ribbonStyles.floating}`;
  }
  return classes;
}

export function Ribbon(props: { floating: boolean }): JSX.Element {
  const urlInfo = useContext(UrlContext);
  const navigate = useNavigate();
  // const preferences = useContext(PreferencesContext);

  // const currentNavScreen = urlInfo.currentTab ?? preferences.web.home_screen;
  const currentNavScreen = urlInfo.currentTab ?? MainNavScreens.TRANSACTIONS;

  function setCurrentTab(to: MainNavScreens) {
    navigate("/" + to.toLowerCase());
  }

  return (
    <div className={getRibbonClasses(props.floating, currentNavScreen)}>
      <div className={ribbonStyles.ribbonLayout}>
        <div className={ribbonStyles.bisectedRibbonContainer}>
          <HorizontalSpacer width={8}></HorizontalSpacer>
          <BreadLogo />
          <HorizontalSpacer width={64}></HorizontalSpacer>
          <button
            className={`${ribbonStyles.mainNavButton} ${
              ribbonStyles.settingsButton
            } ${getSelectedClassIfAppropriate(
              currentNavScreen,
              MainNavScreens.SETTINGS
            )}`}
            onClick={() => {
              setCurrentTab(MainNavScreens.SETTINGS);
            }}
          >
            <div className={layoutStyles.flexH}>
              <Icon
                name={"settings"}
                size={18}
                fill={currentNavScreen === MainNavScreens.SETTINGS}
              ></Icon>
            </div>
          </button>
          <HorizontalSpacer width={44}></HorizontalSpacer>
          <button
            className={`${
              ribbonStyles.mainNavButton
            } ${getSelectedClassIfAppropriate(
              currentNavScreen,
              MainNavScreens.BUDGET
            )}`}
            onClick={() => setCurrentTab(MainNavScreens.BUDGET)}
          >
            <div className={layoutStyles.flexH}>Budget</div>
          </button>
          <HorizontalSpacer width={30}></HorizontalSpacer>
          <button
            className={`${
              ribbonStyles.mainNavButton
            } ${getSelectedClassIfAppropriate(
              currentNavScreen,
              MainNavScreens.TRANSACTIONS
            )}`}
            onClick={() => setCurrentTab(MainNavScreens.TRANSACTIONS)}
          >
            <div className={layoutStyles.flexH}>Transactions</div>
          </button>
          <HorizontalSpacer width={30}></HorizontalSpacer>
          <button
            className={`${
              ribbonStyles.mainNavButton
            } ${getSelectedClassIfAppropriate(
              currentNavScreen,
              MainNavScreens.UNDERSTANDING
            )}`}
            onClick={() => setCurrentTab(MainNavScreens.UNDERSTANDING)}
          >
            <div className={layoutStyles.flexH}>Understanding</div>
          </button>
        </div>
        <div className={ribbonStyles.bisectedRibbonContainer}>
          <SyncTransactionsRibbonButton />
        </div>
      </div>
      {currentNavScreen === MainNavScreens.TRANSACTIONS ? (
        <TransactionsHeader></TransactionsHeader>
      ) : null}
    </div>
  );
}

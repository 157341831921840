import { BreadTransaction } from "breadcommon";
import styles from "./InlineRule.module.scss";
import { Icon } from "../Icon";
import { useContext, useState } from "react";
import { InlineTransactionContext } from "./InlineTransaction";
import { UserContext } from "../firebaseio/UserContext";
import {
  firestoreAddRuleAndUpdateTransactions,
} from "../firebaseio/firestoreIo";
import { FirestoreDocRule } from "breadcommon";

export function InlineRule(props: {
  transaction: BreadTransaction;
  activeRule: FirestoreDocRule | null;
  createRuleFromTransactionWithoutId: () => Omit<FirestoreDocRule, "id">;
  openRuleEditor: () => void;
}) {
  const { transactionSelected, ruleForFieldHighlighting } = useContext(
    InlineTransactionContext
  );
  const user = useContext(UserContext);
  const [activeRuleIcon, setActiveRuleIcon] = useState<string>("gavel");

  function handleClick(activeRule: FirestoreDocRule | null) {
    if (!activeRule) {
      firestoreAddRuleAndUpdateTransactions(
        user.uid,
        props.createRuleFromTransactionWithoutId()
      );
    } else {
      props.openRuleEditor();
    }
  }

  function handleMouseEnter(matchingRule: FirestoreDocRule | null) {
    if (matchingRule === null) {
      matchingRule = { id: "", ...props.createRuleFromTransactionWithoutId() };
    }
    ruleForFieldHighlighting.set(matchingRule);
    setActiveRuleIcon("edit");
  }

  function handleMouseLeave(matchingRule: FirestoreDocRule | null) {
    ruleForFieldHighlighting.set(null);
    setActiveRuleIcon("gavel");
  }

  if (props.transaction.categoryId === null) {
    // Don't allow creating a rule from an uncategorized transaction,
    // since it doesn't make sense.
    return <div className={styles.hiddenRulePlaceholder} />;
  }

  let icon = (
    <Icon
      className={`${styles.reviewedIcon} ${styles.placeholdIcon}`}
      name={"gavel"}
      size={19}
    ></Icon>
  );

  if (props.activeRule !== null) {
    icon = (
      <Icon
        className={styles.activeRuleIcon}
        name={activeRuleIcon}
        size={19}
        tooltip="Click to edit the rule applied to this transaction"
      ></Icon>
    );
  }

  return (
    <div
      onClick={() => handleClick(props.activeRule)}
      className={
        transactionSelected
          ? `${styles.transactionSelected} ${styles.inlineRule}`
          : `${styles.inlineRule}`
      }
      onMouseEnter={() => handleMouseEnter(props.activeRule)}
      onMouseLeave={() => handleMouseLeave(props.activeRule)}
    >
      {icon}
    </div>
  );
}

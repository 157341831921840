import { FirebaseError } from "firebase/app";

// Note messages can have newlines specified by \n.
// UI components can choose to respect these newlines or ignore them by
// deciding to set `white-space: pre-wrap`
export function errorToMsg(error: unknown) {
  if (error instanceof FirebaseError) {
    const firebaseError = error as FirebaseError;
    switch (firebaseError.code) {
      case "auth/user-not-found": {
        return "Incorrect email or password. \nPlease try again.";
      }
      case "auth/wrong-password": {
        return "Incorrect email or password. \nPlease try again.";
      }
      case "auth/too-many-requests": {
        return "Access to this account has been temporarily disabled due to many failed login attempts. Please try again later.";
      }
      case "auth/network-request-failed": {
        return "There was an issue connecting to the internet. Please check your internet connection and try again.";
      }
      case "auth/missing-email": {
        return "Please specify an email address to create an account.";
      }
      case "auth/missing-password": {
        return "Please specify a password to create an account.";
      }
      case "auth/invalid-email": {
        return "Please use a valid email address to create an account.";
      }
      case "auth/weak-password": {
        return "Password must be at least 6 characters. Please try again.";
      }
      case "auth/email-already-in-use": {
        return "This email address is already associated with an account. Please sign in to that existing account or use a different email address.";
      }
      default: {
        return "An unknown error has occurred.";
      }
    }
  }

  return "An unknown error has occurred.";
}

import { FirestoreDocUser } from "breadcommon";
import { createContext, useState } from "react";

export const UserContext = createContext<FirestoreDocUser>({ uid: "" });

export function UserContextProvider(props: {
  children: JSX.Element;
  user: FirestoreDocUser;
}): JSX.Element {
  const [user] = useState<FirestoreDocUser>(props.user);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
}

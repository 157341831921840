import styles from "./AccountsSettings.module.scss";
import { AccountRow } from "./AccountRow";
import { Icon } from "../../Icon";
import { HorizontalSpacer, VerticalSpacer } from "../../utils/Utils";
import { AccountStatuses, BreadAccount } from "breadcommon";
import { useContext, useState } from "react";
import { AccountsContext } from "../../firebaseio/AccountsContext";
import { LoadingInitialDataContext } from "../../LoadUser";
import { Skeleton } from "@mui/material";
import { AddAccountModal } from "./AddAccountModal";

export function AccountsSettings(): JSX.Element {
  const [addAccountModalIsOpen, setAddAccountModalIsOpen] =
    useState<boolean>(false);

  const accounts = useContext(AccountsContext);
  const loadingInitialData = useContext(LoadingInitialDataContext);

  let accountRows: JSX.Element[] = [];
  Array.from(accounts.values())
    .sort((a, b) => a.order_position - b.order_position)
    .forEach((account: BreadAccount, account_id) => {
      if (account.status === AccountStatuses.ACTIVE) {
        accountRows.push(
          <AccountRow key={account_id} account={account}></AccountRow>
        );
      }
    });

  if (
    loadingInitialData.accounts &&
    loadingInitialData.institutions &&
    loadingInitialData.transactions
  ) {
    return (
      <div className={styles.accountsScreenContainer}>
        <Skeleton animation="wave" />
      </div>
    );
  }

  if (accountRows.length === 0) {
    return (
      <div className={styles.accountScreenContainer}>
        <div className={styles.accountRowsCard}>
          <div className={styles.noAccountsPlaceholder}>
            <Icon
              name={"info"}
              size={26}
              className={styles.placeholderInfoIcon}
            ></Icon>
            <HorizontalSpacer width={12}></HorizontalSpacer>
            Add an account to get started!
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.accountScreenContainer}>
      <div>
        <button
          onClick={() => setAddAccountModalIsOpen(true)}
          className={styles.addAccountButton}
        >
          <Icon name={"add"} size={23}></Icon>
          <HorizontalSpacer width={5}></HorizontalSpacer>
          Add Accounts
        </button>
        <VerticalSpacer height={20}></VerticalSpacer>
        {addAccountModalIsOpen ? (
          <AddAccountModal
            isOpen={addAccountModalIsOpen}
            setIsOpen={setAddAccountModalIsOpen}
          ></AddAccountModal>
        ) : null}
      </div>
      <div className={styles.accountRowsCard}>{accountRows}</div>
    </div>
  );
}

import { FunctionComponent } from "react";
import styles from "./BasicModal.module.scss";
import Modal from "react-modal";

type Props = {
  isOpen: boolean;
  width?: number;
  height?: number;
  children: JSX.Element;
};

export const BasicModal: FunctionComponent<Props> = ({
  isOpen,
  width = 500,
  height = 300,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
      ariaHideApp={false}
      style={{
        content: {
          width: `${width}px`,
          height: `${height}px`,
          marginLeft: `${-width / 2}px`,
          marginTop: `${-height / 2}px`,
        },
      }}
    >
      {children}
    </Modal>
  );
};

import { MainNavScreens, firestoreDocPreferencesSchema } from "breadcommon";
import { FirestoreCollectionNames, FirestoreDocPreferences } from "breadcommon";
import { getApp } from "firebase/app";
import { DocumentData, DocumentReference, Unsubscribe, doc, getDoc, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { defaultInitialPreferences } from "./PreferencesContext";

function preferencesDoc(
  userId: string
): DocumentReference<DocumentData, DocumentData> {
  const db = getFirestore(getApp());
  return doc(
    db,
    FirestoreCollectionNames.users.name,
    userId,
    FirestoreCollectionNames.users.userDocs.name,
    FirestoreCollectionNames.users.userDocs.docs.preferences.doc_id
  );
}

export function firestoreDocPreferencesSubscribe(
  userId: string,
  save: (newPreferences: FirestoreDocPreferences) => void,
  markInitialLoadComplete: () => void,
): Unsubscribe {
  return onSnapshot(
    preferencesDoc(userId),
    async (querySnapshot) => {
      const preferencesParseResult = firestoreDocPreferencesSchema.safeParse(querySnapshot.data());
      if (!preferencesParseResult.success) {
        return;
      }
      markInitialLoadComplete();
      save(preferencesParseResult.data);
    }
  );
}

export async function preferencesExists(
  userId: string
): Promise<boolean> {
  const doc = await getDoc(
    preferencesDoc(userId)
  );
  return doc.exists();

}

export async function setInitialPreferences(
  userId: string,
): Promise<void> {
  return setDoc(
    preferencesDoc(userId),
    defaultInitialPreferences
  );
}

async function updatePreference(
  userId: string,
  update: Partial<FirestoreDocPreferences>
): Promise<void> {
  return setDoc(
    preferencesDoc(userId),
    update,
    { merge: true }
  );
}

export async function setWebHomeScreenPreference(
  userId: string,
  homeScreen: MainNavScreens,
): Promise<void> {
  return updatePreference(
    userId,
    { web: { home_screen: homeScreen } }
  );
}

export const FirestoreCollectionNames = {
  users: {
    name: "users",
    plaid_items: {
      name: "plaid_items",
    },
    institutions: {
      name: "institutions",
    },
    accounts: {
      name: "accounts",
    },
    transactions: {
      name: "transactions",
    },
    categories: {
      name: "categories",
    },
    category_groups: {
      name: "category_groups",
    },
    rules: {
      name: "rules",
    },
    budgets: {
      name: "budgets",
    },
    userDocs: {
      name: "user_docs",
      docs: {
        preferences: {
          doc_id: "preferences"
        }
      }
    },
  },
};

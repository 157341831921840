import { useContext } from "react";
import { DollarAmount } from "../common/DollarAmount";
import { AccountsContext } from "../firebaseio/AccountsContext";
import { VerticalSpacer, HorizontalSpacer } from "../utils/Utils";
import styles from "./BalancesScreen.module.scss";

function BalanceTotalCard(props: {
  label: string;
  amount: number;
  type: "asset" | "liability" | "net";
}) {
  function getCardClasses() {
    switch (props.type) {
      case "asset":
        return `${styles.balanceTotalCard} ${styles.assetColor}`;
      case "liability":
        return `${styles.balanceTotalCard} ${styles.liabilityColor}`;
      case "net":
        return `${styles.balanceTotalCard} ${styles.netColor}`;
    }
  }

  return (
    <div className={getCardClasses()}>
      <div className={styles.balanceTotalLabel}>{props.label}</div>
      <VerticalSpacer height={20} />
      <DollarAmount
        n={props.amount}
        includeDecimals={false}
        showPlus={false}
        showMinus={true}
        showDollarSign={true}
        monospaceFont={false}
        align="left"
      />
    </div>
  );
}

export function BalancesScreen() {
  const accounts = useContext(AccountsContext);

  let assetTotal = 0;
  let liabilityTotal = 0;
  let netTotal = 0;

  for (const account of Array.from(accounts.values())) {
    if (!account.current_balance) {
      continue;
    }
    if (account.current_balance > 0) {
      assetTotal += account.current_balance;
    } else if (account.current_balance < 0) {
      liabilityTotal += account.current_balance;
    }
    netTotal += account.current_balance;
  }

  return (
    <div className={styles.balanceTotalsContainer}>
      <BalanceTotalCard label={"Assets"} amount={assetTotal} type={"asset"} />
      <HorizontalSpacer width={20} />
      <BalanceTotalCard
        label={"Liabilities"}
        amount={liabilityTotal}
        type={"liability"}
      />
      <HorizontalSpacer width={20} />
      <BalanceTotalCard label={"Net"} amount={netTotal} type={"net"} />
    </div>
  );
}

import { createContext } from "react";

function setterPlaceholder(arg: any) {}

export type StateWithSetter<T> = [T, React.Dispatch<React.SetStateAction<T>>];

// Creates a context who's value mirrors the value returned by useState
// (i.e. a value and a setter for that value). This allows children who read the
// context to also update the context.
//
// `d` is the default value used in createContext, which according to the
// docs is essentially never used.
//
// The *actual* default value will be set in useState when the state is
// created and then passed into the context's provider element value, like:
//
// <MainNavScreenContext.Provider value={[myState, setMyState]}>
// ...
// </MainNavScreenContext.Provider>
export function createContextWithSetter<T>(
  d: T
): React.Context<StateWithSetter<T>> {
  return createContext<StateWithSetter<T>>([d, setterPlaceholder]);
}

import { Footer } from "./Footer";
import { Header } from "./Header";
import { rawPrivacyPolicy } from "./RawPrivacyPolicy";
import styles from "./PrivacyPolicyPage.module.scss";
import { useEffect } from "react";

export function PrivacyPolicyPage() {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div>
      <Header />
      <div className={styles.mainContainer}>
        <div className={styles.spacingContainer}>
          <div dangerouslySetInnerHTML={{ __html: rawPrivacyPolicy }} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

import { BreadTransaction, FirestoreDocUser } from "breadcommon";
import { createContext, useEffect, useState } from "react";
import { firestoreTransactionsSubscribe } from "./firestoreIo";

export const TransactionsContext = createContext<Map<string, BreadTransaction>>(
  new Map()
);

export function TransactionsContextProvider(props: {
  children: JSX.Element;
  user: FirestoreDocUser;
  markInitialLoadComplete: () => void;
}): JSX.Element {
  const [transactions, setTransactions] = useState<
    Map<string, BreadTransaction>
  >(new Map());

  function updateTransactions(
    txnChanges: Map<string, BreadTransaction>,
    txnDeletions: string[]
  ) {
    setTransactions((prevState) => {
      txnChanges.forEach((update, id) => prevState.set(id, update));
      txnDeletions.forEach((id) => prevState.delete(id));
      return prevState;
    });
  }

  useEffect(() => {
    return firestoreTransactionsSubscribe(
      props.user.uid,
      updateTransactions,
      props.markInitialLoadComplete
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.uid]);

  return (
    <TransactionsContext.Provider value={transactions}>
      {props.children}
    </TransactionsContext.Provider>
  );
}

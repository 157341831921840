import * as z from "zod";

export const firestoreDocRuleSchema = z.object({
  id: z.string(),
  criteria: z.object({
    account_id: z.string().nullable(),
    description: z.string().nullable(),
    partial_description: z.string().nullable().optional(),
    merchant_name: z.string().nullable(),
    type: z.string().nullable().optional(), // optional as well because there are already some saved rules without them
    subtype: z.string().nullable().optional(), // optional as well because there are already some saved rules without them
  }),
  action: z.object({
    category_id: z.string().nullable(),
  }),
  created_timestamp_secs: z.number(),
  order_position: z.number(),
});

export type FirestoreDocRule = z.infer<typeof firestoreDocRuleSchema>;

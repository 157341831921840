import styles from "./RuleSettings.module.scss";
import buttonStyles from "../common/Buttons.module.scss";
import { useContext } from "react";
import { RulesContext } from "../firebaseio/RulesContext";
import { getNullFieldValueCriterionEquivalent } from "breadcommon";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { FirestoreDocRule } from "breadcommon";
import { VerticalSpacer } from "../utils/Utils";
import { firestoreDeleteRuleAndUpdateTransactions } from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { InlineAccount } from "../common/InlineAccount";

export function RuleSettings(): JSX.Element {
  const rules = useContext(RulesContext);
  const user = useContext(UserContext);
  return (
    <div>
      <div className={styles.pageHeader}>
        How Rules work:
        <ul>
          <li>A Rule matches a Transaction if the Transaction meets the criteria of the Rule</li>
          <li>If more than one Rule matches a transaction, the rule with the highest priority is used (this page is sorted in priority from highest to lowest)</li>
          <li>Whenever a Rule is added, deleted, or modified, we reapply all rules to all Unreviewed transactions to find the best match</li>
          <li>If a Transaction is already marked as Reviewed, then adding, deleting, or updating a Rule will not impact that Transaction</li>
        </ul>
      </div>
      <VerticalSpacer height={15} />
      {Array.from(rules.values()).sort((a, b) => a.order_position - b.order_position).map((rule) => {
        return (
          <>
            <div className={styles.card} key={rule.id}>
              <div className={styles.header}>
                Rule {rule.id}
              </div>
              <VerticalSpacer height={8} />
              <RulePreview rule={rule} />
              <VerticalSpacer height={8} />
              <div className={styles.footer}>
                <div>Priority: {-rule.order_position}</div>
                <button
                  className={buttonStyles.dangerousButton}
                  onClick={() => firestoreDeleteRuleAndUpdateTransactions(user.uid, rule.id)}
                >
                  Delete Rule
                </button>
              </div>
            </div>
            <VerticalSpacer height={10} />
          </>
        );
      })}
    </div >
  );
}

export function RulePreview(
  props: {
    rule: FirestoreDocRule
  }
): JSX.Element {
  const categories = useContext(CategoriesContext);
  const rule = props.rule;
  const category = categories.get(rule.action.category_id ?? "")
  const categoryString = `${category?.emoji} ${category?.name}` ?? rule.action.category_id ?? "MISSING CATEGORY_ID";
  const isEmpty = (criterion_value: string): boolean => {
    return criterion_value === getNullFieldValueCriterionEquivalent();
  }
  const criteria = rule.criteria;
  return <div className={styles.rulePreview}>Assign the category <b>{categoryString}</b> if all of the following are true for a Transaction:
    <ul>
      {criteria.account_id &&
        <li key="account_id">
          <b>Account</b> is {isEmpty(criteria.account_id) ? "empty" : <InlineAccount account_id={criteria.account_id} logoGap={5} />}
        </li>}
      {criteria.merchant_name &&
        <li key="merchant_name">
          <b>Merchant</b> is {isEmpty(criteria.merchant_name) ? "empty" : `exactly "${criteria.merchant_name}"`}
        </li>}
      {criteria.type &&
        <li key="type">
          <b>Type</b> is {isEmpty(criteria.type) ? "empty" : criteria.type}
        </li>}
      {criteria.subtype &&
        <li key="subtype">
          <b>Subtype</b> is {isEmpty(criteria.subtype) ? "empty" : criteria.subtype}
        </li>}
      {criteria.description &&
        <li key="description">
          <b>Description</b> is {isEmpty(criteria.description) ? "empty" : `exactly "${criteria.description}"`}
        </li>}
      {criteria.partial_description &&
        <li key="partial_description">
          <b>Description</b> contains "{criteria.partial_description}"
        </li>}
    </ul>
  </div>
}
import * as z from "zod";
import { FirestoreDocBudget } from "./FirestoreDocBudget";
import dayjs, { Dayjs } from "dayjs";
import Mexp from "math-expression-evaluator";

const mexp = new Mexp();

export type BudgetLineItem = {
  category_id: string,
  formula: string,
  formula_is_valid: boolean,
  amount: number,
}

export type BreadBudget = {
  id: string,
  name: string,
  beginDateTimestampSecs: Dayjs,
  endDateTimestampSecs: Dayjs,
  line_items: Map<string, BudgetLineItem>
}

export const parseBreadBudget = (
  budget_id: string,
  budget: FirestoreDocBudget
): BreadBudget => {
  let parsed_line_items = new Map<string, BudgetLineItem>();
  Object.entries(budget.line_items).forEach((entry: [string, { formula: string }]) => {
    const [category_id, line_item] = entry;
    let amount = 0;
    let formula_is_valid = false;
    try {
      amount = line_item.formula === "" ? 0 : mexp.eval(line_item.formula);
      formula_is_valid = true;
    } catch { }
    parsed_line_items.set(category_id, {
      formula: line_item.formula,
      category_id: category_id,
      formula_is_valid: formula_is_valid,
      amount: amount,
    })
  });
  return {
    id: budget_id,
    name: budget.name,
    beginDateTimestampSecs: dayjs.unix(budget.begin_date_timestamp_secs),
    endDateTimestampSecs: dayjs.unix(budget.end_date_timestamp_secs),
    line_items: parsed_line_items
  }
}
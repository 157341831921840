import styles from "./Icon.module.scss";

export type IconProps = {
  name: string;
  size: number;
  fill?: boolean;
  className?: string;
  onClick?: undefined | ((args: any) => void);
  tooltip?: string | undefined
};

// "name" is the name of the icon at https://fonts.google.com/icons
// TODO: take in filled, size, color, etc. in props and set style dynamically
export function Icon({
  name,
  size,
  fill = false,
  className = "",
  onClick = undefined,
  tooltip = undefined,
}: IconProps): JSX.Element {
  let fillStyle = fill ? styles.fill : styles.outline;
  return (
    <div
      className={`material-symbols-rounded ${fillStyle} ${className}`}
      style={{ fontSize: `${size}px` }}
      onClick={onClick}
      title={tooltip}
    >
      {name}
    </div>
  );
}

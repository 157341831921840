import { BreadTransaction, FirestoreDocUser } from "breadcommon";
import { createContext, useEffect, useState } from "react";
import { firestoreRulesSubscribe } from "./firestoreIo";
import { FirestoreDocRule } from "breadcommon";

export const RulesContext = createContext<Map<string, FirestoreDocRule>>(
  new Map()
);

export function RulesContextProvider(props: {
  children: JSX.Element;
  user: FirestoreDocUser;
  markInitialLoadComplete: () => void;
}): JSX.Element {
  const [rules, setRules] = useState<Map<string, FirestoreDocRule>>(new Map());

  function updateRules(
    ruleChanges: Map<string, FirestoreDocRule>,
    ruleDeletions: string[]
  ) {
    setRules((prevState) => {
      ruleChanges.forEach((update, id) => prevState.set(id, update));
      ruleDeletions.forEach((id) => prevState.delete(id));
      return prevState;
    });
  }

  useEffect(() => {
    return firestoreRulesSubscribe(
      props.user.uid,
      updateRules,
      props.markInitialLoadComplete
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.uid]);

  return (
    <RulesContext.Provider value={rules}>
      {props.children}
    </RulesContext.Provider>
  );
}

import { getAuth, signOut } from "firebase/auth";
import settingsStyles from "./SettingsScreen.module.scss";

export function ProfileSettings(): JSX.Element {
  async function logOut() {
    const auth = getAuth();
    await signOut(auth);
  }

  return (
    <button className={settingsStyles.basicButton} onClick={logOut}>
      Sign out
    </button>
  );
}

import styles from "./InlineRuleEditorModal.module.scss"
import buttonStyles from "../common/Buttons.module.scss"
import { BreadTransaction, FirestoreDocRule, getNullFieldValueCriterionEquivalent, transactionMatchesSpecificRule } from "breadcommon";
import { BasicModal } from "../common/BasicModal";
import { Icon } from "../Icon";
import { useContext, useState } from "react";
import { firestoreDeleteRuleAndUpdateTransactions, firestoreUpdateRuleAndUpdateTransactions } from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { HorizontalSpacer, VerticalSpacer } from "../utils/Utils";
import { Checkbox } from "@mui/material";
import { RulePreview } from "../settings_screen/RuleSettings";
import { InlineTransactionContext } from "./InlineTransaction";
import { DropDown } from "../common/DropDown";

export const InlineRuleEditorModal = (props: {
  rule: FirestoreDocRule,
  transaction: BreadTransaction,
  isOpen: boolean,
  close: () => void,
}) => {
  const user = useContext(UserContext);
  const { ruleForFieldHighlighting } = useContext(
    InlineTransactionContext
  );
  const [modifiedRule, setModifiedRule] = useState<FirestoreDocRule>(props.rule);

  // There is some complexity here because criteriaIncludesDescription
  // is computed from modifiedRule, so the state setting needs to be done carefully.
  const [descriptionMatchType, _setDescriptionMatchType] = useState(
    modifiedRule.criteria.partial_description == null ? 'exact' : 'contains'
  );
  const [partialDescription, _setPartialDescription] = useState<string | null>(props.transaction.description);
  const criteriaIncludesDescription = modifiedRule.criteria.description != null || modifiedRule.criteria.partial_description != null;
  const setDescriptionCriteria = (
    criteriaIncludesDescription: boolean,
    descriptionMatchType: string,
    partialDescription: string | null,
  ) => {
    _setPartialDescription(partialDescription);
    _setDescriptionMatchType(descriptionMatchType);
    if (criteriaIncludesDescription) {
      if (descriptionMatchType === 'exact') {
        matchField('description', props.transaction.description);
        ignoreField('partial_description')
      } else {
        matchField('partial_description', partialDescription);
        ignoreField('description')
      }
    } else {
      ignoreField('description');
      ignoreField('partial_description');
    }
  };

  const modifyRule = (field: string, value: string | null) => {
    setModifiedRule(prevRule => ({
      ...prevRule,
      criteria: {
        ...prevRule.criteria,
        [field]: value
      }
    }));
  };
  const ignoreField = (field: string) => {
    modifyRule(field, null);
  }
  const matchField = (field: string, value: string | null | undefined) => {
    modifyRule(
      field,
      (value == null) ? getNullFieldValueCriterionEquivalent() : value
    );
  }

  const cancel = () => {
    setModifiedRule(props.rule);
    setDescriptionCriteria(
      criteriaIncludesDescription,
      modifiedRule.criteria.partial_description == null ? 'exact' : 'contains',
      props.transaction.description
    );
    props.close();
  };

  return <BasicModal
    isOpen={props.isOpen}
    width={860}
    height={800}
  >
    <div className={styles.contentContainer}>
      <div className={styles.headerContainer}>
        <div>
          <div className={styles.title}>Edit Rule</div>
          <VerticalSpacer height={8} />
          <div className={styles.subtitle}>
            You are editing rule {props.rule.id} using transaction {props.transaction.id}.
            Use the checkboxes on the right to select which parts of the transaction you want to use for this rule.
            Your changes will not be saved or applied to your transactions until you hit Save. When you Save, it will apply
            to all Unreviewed transactions. If a Transaction is already marked as Reviewed, then it will not be impacted.
          </div>
        </div>
        <Icon
          name="close"
          size={23}
          className={styles.closeButton}
          onClick={() => cancel()}
        />
      </div>
      <VerticalSpacer height={24} />

      <div className={styles.criteriaSelectionContainer}>
        <div className={styles.row}>
          <div>Account ID is {props.transaction.account_id == null ? "empty" : `exactly "${props.transaction.account_id}"`}</div>
          <div>
            <Checkbox checked={modifiedRule.criteria.account_id !== null}
              onChange={(e) => e.target.checked ? matchField("account_id", props.transaction.account_id) : ignoreField("account_id")} />
          </div>
        </div>
        <div className={styles.row}>
          <div>Merchant is {props.transaction.merchant?.name == null ? "empty" : `exactly "${props.transaction.merchant?.name}"`}</div>
          <div>
            <Checkbox checked={modifiedRule.criteria.merchant_name !== null}
              onChange={(e) => e.target.checked ? matchField("merchant_name", props.transaction.merchant?.name) : ignoreField("merchant_name")} />
          </div>
        </div>
        <div className={styles.row}>
          <div>Type is {props.transaction.type == null ? "empty" : `exactly "${props.transaction.type}"`}</div>
          <div>
            <Checkbox checked={modifiedRule.criteria.type !== null}
              onChange={(e) => e.target.checked ? matchField("type", props.transaction.type) : ignoreField("type")} />
          </div>
        </div>
        <div className={styles.row}>
          <div>Subtype is {props.transaction.subtype == null ? "empty" : `exactly "${props.transaction.subtype}"`}</div>
          <div>
            <Checkbox checked={modifiedRule.criteria.subtype !== null}
              onChange={(e) => e.target.checked ? matchField("subtype", props.transaction.subtype) : ignoreField("subtype")} />
          </div>
        </div>
        {
          <div className={styles.row}>
            <div className={styles.row}>Description
              {props.transaction.description == null ? " is empty" : (
                <div className={styles.row}>
                  <HorizontalSpacer width={8} />
                  <DropDown
                    entries={new Map([
                      ['exact', 'is exactly'],
                      ['contains', 'contains'],
                    ])}
                    initEntryId={descriptionMatchType}
                    width={150}
                    onChange={(descriptionMatchType) => setDescriptionCriteria(
                      criteriaIncludesDescription,
                      descriptionMatchType,
                      partialDescription,
                    )}
                  />
                  <HorizontalSpacer width={8} />
                  {descriptionMatchType === 'exact' ?
                    props.transaction.description
                    : (
                      <div className={styles.inputWrapper}>
                        <input
                          type="text"
                          value={partialDescription ?? ''}
                          onChange={(e) => setDescriptionCriteria(
                            criteriaIncludesDescription,
                            descriptionMatchType,
                            e.target.value,
                          )}
                          maxLength={props.transaction.description.length + 100}
                        />
                        {partialDescription !== props.transaction.description &&
                          <Icon
                            name="replay"
                            size={23}
                            className={styles.inputIcon}
                            tooltip="Reset to the transaction's description"
                            onClick={() => setDescriptionCriteria(
                              criteriaIncludesDescription,
                              descriptionMatchType,
                              props.transaction.description,
                            )} />
                        }
                      </div>
                    )}
                </div>
              )}
            </div>
            <div>
              <Checkbox
                checked={criteriaIncludesDescription}
                onChange={(e) => setDescriptionCriteria(
                  e.target.checked,
                  descriptionMatchType,
                  partialDescription,
                )}
              />
            </div>
          </div>
        }
      </div>

      <VerticalSpacer height={24} />
      <div className={styles.title}>Rule Preview</div>
      <VerticalSpacer height={12} />
      <RulePreview rule={modifiedRule} />
      <VerticalSpacer height={24} />
      {
        transactionMatchesSpecificRule(props.transaction, modifiedRule) ? null
          : <div className={styles.warning}>
            WARNING: your updated rule does not match this transaction
          </div>
      }

      <div className={styles.bottomRowContainer}>
        <div> {/* left footer button(s) */}
          <button
            className={buttonStyles.dangerousButton}
            onClick={() => {
              firestoreDeleteRuleAndUpdateTransactions(user.uid, props.rule.id);
              ruleForFieldHighlighting.set(null);
              props.close();
            }}
            title="When you delete a rule, it will reapply all remaining rules to all 
            Unreviewed transactions to find the new best match (if one exists).
            If a Transaction is already marked as Reviewed, then it will not be impacted."
          >
            Delete Rule
          </button>
        </div>
        <div> {/* right footer button(s) */}
          <button
            className={styles.cancelButton}
            onClick={() => cancel()}>
            Cancel
          </button>
          <button
            className={buttonStyles.saveButton}
            onClick={() => {
              firestoreUpdateRuleAndUpdateTransactions(
                user.uid,
                props.rule.id,
                modifiedRule,
              );
              if (transactionMatchesSpecificRule(props.transaction, modifiedRule)) {
                ruleForFieldHighlighting.set(modifiedRule);
              } else {
                ruleForFieldHighlighting.set(null);
              }
              props.close();
            }}
            disabled={Object.values(modifiedRule.criteria).every(criteria => criteria == null)}
            title="When you save, it will reapply all rules to all Unreviewed transactions to find the new best match. 
            If a Transaction is already marked as Reviewed, then it will not be impacted."
          >
            Save Changes
          </button>
        </div>
      </div>
    </div >
  </BasicModal >;
};
import { FirestoreDocUser, FirestoreDocPreferences } from "breadcommon";
import { createContext, useEffect, useState } from "react";
import { firestoreDocPreferencesSubscribe } from "./preferencesIo";
import { MainNavScreens } from "breadcommon";

export const defaultInitialPreferences: FirestoreDocPreferences = {
  web: {
    home_screen: MainNavScreens.TRANSACTIONS
  }
};

export const PreferencesContext = createContext<FirestoreDocPreferences>(defaultInitialPreferences);

export function PreferencesContextProvider(props: {
  children: JSX.Element;
  user: FirestoreDocUser;
  markInitialLoadComplete: () => void;
}): JSX.Element {
  const [webState, setPreferences] = useState<FirestoreDocPreferences>(defaultInitialPreferences);

  useEffect(() => {
    return firestoreDocPreferencesSubscribe(
      props.user.uid,
      setPreferences,
      props.markInitialLoadComplete,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.uid]);

  return (
    <PreferencesContext.Provider value={webState}>
      {props.children}
    </PreferencesContext.Provider>
  )
}
import { BreadTransaction, FirestoreDocUser } from "breadcommon";
import { createContext, useEffect, useState } from "react";
import { firestoreBudgetsSubscribe } from "./firestoreIo";
import { FirestoreDocBudget, BreadBudget, parseBreadBudget } from "breadcommon";

export const BudgetsContext = createContext<Map<string, BreadBudget>>(
  new Map()
);

export function BudgetsContextProvider(props: {
  children: JSX.Element;
  user: FirestoreDocUser;
  markInitialLoadComplete: () => void;
}): JSX.Element {
  const [budgets, setBudgets] = useState<Map<string, BreadBudget>>(new Map());

  function updateBudgets(
    budgetChanges: Map<string, FirestoreDocBudget>,
    budgetDeletions: string[]
  ) {
    setBudgets((prevState) => {
      budgetChanges.forEach((update: FirestoreDocBudget, id) => prevState.set(id, parseBreadBudget(id, update)));
      budgetDeletions.forEach((id) => prevState.delete(id));
      return prevState;
    });
  }

  useEffect(() => {
    return firestoreBudgetsSubscribe(
      props.user.uid,
      updateBudgets,
      props.markInitialLoadComplete
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.uid]);

  return (
    <BudgetsContext.Provider value={budgets}>
      {props.children}
    </BudgetsContext.Provider>
  );
}

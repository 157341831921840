import { BreadInstitution, FirestoreDocUser } from "breadcommon";
import { createContext, useEffect, useState } from "react";
import { firestoreInstitutionsSubscribe } from "./firestoreIo";

export const InstitutionsContext = createContext<Map<string, BreadInstitution>>(
  new Map()
);

export function InstitutionsContextProvider(props: {
  children: JSX.Element;
  user: FirestoreDocUser;
  markInitialLoadComplete: () => void;
}): JSX.Element {
  const [Institutions, setInstitutions] = useState<
    Map<string, BreadInstitution>
  >(new Map());

  useEffect(() => {
    return firestoreInstitutionsSubscribe(
      props.user.uid,
      setInstitutions,
      props.markInitialLoadComplete
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.uid]);

  return (
    <InstitutionsContext.Provider value={Institutions}>
      {props.children}
    </InstitutionsContext.Provider>
  );
}
